import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import TemplateProdForm from './components/TemplateProdForm';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useStyles } from 'views/utilities/Style';
import { useNavigate } from 'react-router';
import useFetch from 'hooks/useFetch';
import Loader from 'ui-component/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { SetDoc2Duplicate } from 'store/services/api';
import { useTranslation } from 'react-i18next';

const AddItemTemplateForm = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const docToDuplicate = useSelector((state) => state.pim.docToDuplicate);
    const [isTemplate, setIsTemplate] = useState(false);

    // formatting the Request DATA
    // >>>
    const itemGroupsReqData = {
        doctype: 'Item Group',
        fields: ['`tabItem Group`.`name`'],
        filters: [['Item Group', 'is_group', '=', 1]],
        start: 0,
        page_length: 500
    };

    const brandReqData = {
        doctype: 'Brand',
        fields: ['`tabBrand`.`name`'],
        start: 0,
        page_length: 500
    };

    const itemTaxReqData = {
        doctype: 'Item Tax Template',
        fields: ['`tabItem Tax Template`.`name`'],
        filters: [['Item Tax Template', 'company', '=', globalDefaults.default_company]],
        start: 0,
        page_length: 500
    };

    const itemAttributesReqData = {
        doctype: 'Item Attribute',
        fields: ['`tabItem Attribute`.`name`'],
        start: 0,
        page_length: 500
    };

    const supplierReqData = {
        doctype: 'Supplier',
        fields: ['`tabSupplier`.`name`'],
        start: 0,
        page_length: 50
    };

    const companiesReqData = {
        doctype: 'Company',
        fields: ['`tabCompany`.`name`'],
        start: 0,
        page_length: 500
    };

    const priceListsReqData = {
        doctype: 'Price List',
        fields: ['`tabPrice List`.`name`'],
        filters: [['Price List', 'enabled', '=', 1]],
        start: 0,
        page_length: 500
    };

    const warehouseReqData = {
        doctype: 'Warehouse',
        fields: ['`tabWarehouse`.`name`', '`tabWarehouse`.`company`'],
        filters: [['Warehouse', 'company', '=', globalDefaults.default_company]],
        start: 0,
        page_length: 500
    };

    const itemCollectionReqData = {
        doctype: 'Item Collection',
        fields: ['`tabItem Collection`.`name`'],
        start: 0,
        page_length: 500
    };

    const itemUOMReqData = {
        doctype: 'UOM',
        fields: ['`tabUOM`.`name`'],
        start: 0,
        page_length: 500
    };

    const tablelibreReqData = {
        doctype: 'Table Libre',
        fields: ['`tabTable Libre`.`name`', '`tabTable Libre`.`table_code`', '`tabTable Libre`.`libelle`']
    };

    const currencyReqData = {
        doctype: 'Currency',
        fields: ['`tabCurrency`.`name`'],
        start: 0,
        page_length: 20
    };

    //const familleReqData = {
    // doctype: 'Famille',
    // fields: ['`tabFamille`.`name`'],
    //start: 0,
    //page_length: 20
    //};

    const sousfamilleReqData = {
        doctype: 'SousFamille',
        fields: ['`tabSousFamille`.`name`', '`tabSousFamille`.`famille`', '`tabSousFamille`.`libelle`'],
        start: 0,
        page_length: 20
    };
    const souslibreReqData = {
        doctype: 'SousTable Libre',
        fields: ['`tabSousTable Libre`.`name`', '`tabSousTable Libre`.`table_libre`'],
        start: 0,
        page_length: 500
    };
    // fetching DATA
    // >>>
    const { data: itemGroups, isPending } = useFetch(`/api/get-list`, itemGroupsReqData, 'POST');
    const { data: companies, isCompaniesPending } = useFetch(`/api/get-list`, companiesReqData, 'POST');
    const { data: warehouses, isWarehousesPending } = useFetch(`/api/get-list`, warehouseReqData, 'POST');
    const { data: brands, isBrandsPending } = useFetch(`/api/get-list`, brandReqData, 'POST');
    const { data: itemTaxTemplates, isTaxesPending } = useFetch(`/api/get-list`, itemTaxReqData, 'POST');
    const { data: itemAttributes, isAttributesPending } = useFetch(`/api/get-list`, itemAttributesReqData, 'POST');
    const { data: priceLists, isPriceListsPending } = useFetch(`/api/get-list`, priceListsReqData, 'POST');
    const { data: tablelibreLists, isTableLibreListsPending } = useFetch(`/api/get-list`, tablelibreReqData, 'POST');
    const { data: currencyLists, isCurrencyPending } = useFetch(`/api/get-list`, currencyReqData, 'POST');
    //const { data: familleLists, isFammillePending } = useFetch(`/api/get-list`, familleReqData, 'POST');
    const { data: sousfamilleLists, isSousFamillePending } = useFetch(`/api/get-list`, sousfamilleReqData, 'POST');
    const { data: itemCollections, isitemCollectionsPending } = useFetch(`/api/get-list`, itemCollectionReqData, 'POST');
    const { data: uoms, isUomsPending } = useFetch(`/api/get-list`, itemUOMReqData, 'POST');
    const { data: suppliers, isSuppliersPending } = useFetch(`/api/get-list`, supplierReqData, 'POST');
    const { data: soustablelibreLists, isSousTableLibreListsPending } = useFetch('/api/get-list', souslibreReqData, 'POST');
    const backLink = () => {
        navigate(-1);
        dispatch(SetDoc2Duplicate(null));
    };

    const title = (
        <>
            <Box display={'flex'} flexDirection={'column'}>
                <ArrowBackIcon className={classes.backLink} onClick={() => backLink()} />
                {docToDuplicate
                    ? t('products:Dupliquer_le_produit')
                    : isTemplate
                    ? t('products:createModelProduct')
                    : t('products:createProduct')}
            </Box>
            {/*<FormControlLabel*/}
            {/*    control={<Checkbox checked={isTemplate} onChange={() => setIsTemplate(!isTemplate)} />}*/}
            {/*    label={<Typography fontWeight={500}>{t('products:productTemplate')}</Typography>}*/}
            {/*/>*/}
        </>
    );

    useEffect(() => {
        if (docToDuplicate) {
            if (docToDuplicate?.attributes?.length !== 0) {
                setIsTemplate(false);
            } else {
                setIsTemplate(false);
            }
        }
    }, [docToDuplicate]);

    if (
        isPriceListsPending ||
        isAttributesPending ||
        isTaxesPending ||
        isBrandsPending ||
        isWarehousesPending ||
        isCompaniesPending ||
        isSousTableLibreListsPending ||
        isitemCollectionsPending ||
        isUomsPending ||
        isTableLibreListsPending ||
        isSuppliersPending ||
        isSousFamillePending ||
        isCurrencyPending ||
        isPending
    )
        return <Loader />;

    return (
        <Box>
            <MainCard title={title}>
                {isTemplate && (
                    <TemplateProdForm
                        isAdd={true}
                        itemGroups={itemGroups}
                        companies={companies}
                        warehouses={warehouses}
                        tablelibreLists={tablelibreLists}
                        //familleLists={familleLists}
                        sousfamilleLists={sousfamilleLists}
                        soustablelibreLists={soustablelibreLists}
                        currencyLists={currencyLists}
                        suppliers={suppliers}
                        brands={brands}
                        itemTaxTemplates={itemTaxTemplates}
                        itemAttributes={itemAttributes}
                        priceLists={priceLists}
                        itemCollections={itemCollections}
                        uoms={uoms}
                    />
                )}
                {!isTemplate && (
                    <TemplateProdForm
                        isAdd={true}
                        isSimple={true}
                        tablelibreLists={tablelibreLists}
                        // familleLists={familleLists}
                        sousfamilleLists={sousfamilleLists}
                        soustablelibreLists={soustablelibreLists}
                        currencyLists={currencyLists}
                        suppliers={suppliers}
                        itemGroups={itemGroups}
                        companies={companies}
                        warehouses={warehouses}
                        brands={brands}
                        itemTaxTemplates={itemTaxTemplates}
                        itemAttributes={itemAttributes}
                        priceLists={priceLists}
                        itemCollections={itemCollections}
                        uoms={uoms}
                    />
                )}
            </MainCard>
        </Box>
    );
};

export default AddItemTemplateForm;
