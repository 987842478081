import { Button, Chip, Grid, Modal } from '@mui/material';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import WidgetsIcon from '@mui/icons-material/Widgets';

import useFetch from 'hooks/useFetch';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SetNotification } from 'store/services/api';
import { useTranslation } from 'react-i18next';

import MainCard from 'ui-component/cards/MainCard';
import Loader from 'ui-component/Loader';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { modalStyle } from 'views/utilities/ModalStyle';
import { itemFields } from './components/itemFieldsValues';

import {
    DndContext,
    closestCenter,
    rectIntersection,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    useDroppable,
    useDraggable,
    DragOverlay,
    defaultDropAnimation
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import { LoadingButton } from '@mui/lab';

const CustomizeFormItem = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation(['common', 'products']);

    const globalDefaults = useSelector((state) => state.login.globalDefaults);

    const {
        data: mandatoryFieldsInLara,
        refetch,
        isPending
    } = useFetch(`/api/product-fields`, { company: globalDefaults.default_company }, 'POST');

    const [loadingState, setLoadingState] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [selectedSectionFields, setSelectedSectionFields] = useState([]);
    const [mandatoryFields, setMandatoryFields] = useState([]);

    const [sections, setSections] = useState([]);
    const [selectedSection, setSelectedSection] = useState(null);

    const handleSelect = (selectedSection) => {
        setSelectedSection(selectedSection);
        const filteredFields = itemFields?.filter((el) => el?.section === selectedSection);
        if (Array.isArray(filteredFields)) {
            setSelectedSectionFields(filteredFields);
        }
    };

    const handleSaveMandatoryFields = () => {
        setLoadingState(true);
        const data = {
            sections: JSON.stringify(sections),
            product_required_fields: JSON.stringify(mandatoryFields),
            company: globalDefaults.default_company
        };
        const header = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem('user'))?.token
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/save-product-fields`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setLoadingState(false);
                if (!res.ok) {
                    dispatch(SetNotification({ message: "Une erreur s'est produite lors de l'enregistrement", code: 'error' }));
                }
                return res.json();
            })
            .then((data) => {
                data && dispatch(SetNotification({ message: `Les paramètres des champs ont été modifiés avec succès`, code: 'success' }));
                refetch({});
            });
    };

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            {t('common:tatt')}
        </Box>
    );

    const addCustomField = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:addField')}>
                {t('common:comingSoon')}!
            </MainCard>
        </Box>
    );

    const SortableItem = (props) => {
        const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.sectionId });

        const style = {
            transform: CSS.Transform.toString(transform),
            transition
        };

        const onMouseDown = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };
        // console.log('Id sec:', props.label);
        return (
            <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
                <Chip
                    onClick={() => handleSelect(props.sectionId)}
                    onMouseDown={onMouseDown}
                    key={props.label}
                    size="medium"
                    label={t(`products:${props.label.replace(/\s/g, '')}`)}
                    sx={{
                        backgroundColor: selectedSection === props.sectionId ? theme.palette.primary.main : theme.palette.secondary.main,
                        color: selectedSection === props.sectionId ? theme.palette.secondary.main : theme.palette.primary.main,
                        justifyContent: 'space-between',
                        width: '100%',
                        borderRadius: 1,
                        fontSize: 14,
                        fontWeight: 700,
                        '&:active': {
                            boxShadow: 0
                        },
                        '&:hover': {
                            background: selectedSection === props.sectionId ? theme.palette.primary.dark : theme.palette.secondary.light
                        }
                    }}
                />
            </div>
        );
    };

    const SortableChips = () => {
        const sensors = useSensors(
            useSensor(PointerSensor, {
                activationConstraint: {
                    distance: 5
                }
            }),
            useSensor(KeyboardSensor, {
                coordinateGetter: sortableKeyboardCoordinates
            })
        );

        const handleDragEnd = (event) => {
            const { active, over } = event;
            if (active && over && active.id !== over.id && active.id !== 'basic_section' && over.id !== 'basic_section') {
                setSections((items) => {
                    const oldIndex = items.findIndex((item) => item.id === active.id);
                    const newIndex = items.findIndex((item) => item.id === over.id);
                    return arrayMove(items, oldIndex, newIndex);
                });
            }
        };

        const sectionsVisible = useMemo(() => {
            const sections = [
                { id: 'basic_section', label: 'Informations de base' },
                { id: 'pricing_section', label: 'Tarifs' },
                { id: 'famille', label: 'famille' },
                { id: 'table_libre', label: 'table_libre' }
            ];
            return sections;
        }, []);

        const updatedSections = sections.map((section) => {
            if (section.id === 'famille') {
                return { ...section, label: 'famille' };
            }
            if (section.id === 'table_libre') {
                return { ...section, label: 'table_libre' };
            }
            return section;
        });

        //useEffect(() => {
        // const newSection = { id: 'table_libre', label: 'Table Libre' };

        // let updatedSections = itemSections;

        //if (mandatoryFieldsInLara?.[0]?.sections) {
        // console.log('Updating sections state:', JSON.parse(mandatoryFieldsInLara?.[0]?.sections));
        // updatedSections = [...JSON.parse(mandatoryFieldsInLara?.[0]?.sections)];
        // } else {
        //console.log('Fallback to itemSections:', itemSections);
        // }

        //if (!updatedSections.some((section) => section.id === newSection.id)) {
        // updatedSections.push(newSection);
        //}

        //setSections(updatedSections);
        //}, [mandatoryFieldsInLara]);

        //console.log('updatedSections:', updatedSections);
        //console.log('sections:', sections);

        return (
            <PerfectScrollbar style={{ overflowX: 'hidden', height: 400 }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                        modifiers={[restrictToVerticalAxis]}
                    >
                        <SortableContext items={sectionsVisible} strategy={verticalListSortingStrategy}>
                            {sectionsVisible.map((section) => (
                                <SortableItem label={section.label} sectionId={section.id} key={section.id} />
                            ))}
                        </SortableContext>
                    </DndContext>
                </div>
            </PerfectScrollbar>
        );
    };

    function DraggableChip({ section, label, fieldname }) {
        const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
            id: fieldname,
            data: { label, section }
        });

        const style = {
            transform: CSS.Transform.toString(transform),
            opacity: isDragging ? 0 : 1
        };

        const onMouseDown = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };

        return (
            <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
                <Chip
                    onMouseDown={onMouseDown}
                    key={label}
                    size="medium"
                    label={t(`products:${label.replace(/\s/g, '')}`)}
                    clickable
                    icon={<DragIndicatorIcon color="primary" />}
                    sx={{
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.primary.main,
                        justifyContent: 'flex-start',
                        width: '100%',
                        borderRadius: 1,
                        fontSize: 14,
                        fontWeight: 500,
                        '&:active': {
                            boxShadow: 0
                        },
                        '&:hover': {
                            background: theme.palette.secondary.light
                        }
                    }}
                />
            </div>
        );
    }

    function DroppableContainer({ children }) {
        const { setNodeRef, isOver } = useDroppable({ id: 'droppable-area' });

        return (
            <div
                id="droppable-area"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 400,
                    gap: 5
                }}
                ref={setNodeRef}
            >
                {children}
            </div>
        );
    }

    const DraggableChips = () => {
        const [activeField, setActiveField] = useState(null);
        const sensors = useSensors(
            useSensor(PointerSensor, {
                activationConstraint: {
                    distance: 5
                }
            }),
            useSensor(KeyboardSensor, {
                coordinateGetter: sortableKeyboardCoordinates
            })
        );

        const champsVisible = useMemo(() => {
            const visibleSectionIds = ['basic_section', 'pricing_section', 'famille', 'table_libre'];
            return selectedSectionFields?.filter((field) => visibleSectionIds.includes(field.section));
        }, [selectedSectionFields]);

        const visibleMandatoryFields = useMemo(() => {
            const visibleSectionIds = ['basic_section', 'pricing_section', 'famille', 'table_libre'];
            return mandatoryFields?.filter((field) => visibleSectionIds.includes(field.section));
        }, [mandatoryFields]);

        const handleDragStart = ({ active }) => {
            setActiveField({
                label: active.data.current.label,
                fieldname: active.id,
                section: active.data.current.section
            });
        };

        const handleDragEnd = (event) => {
            const { active, over } = event;
            let data = mandatoryFields;

            if (event.over?.id !== 'droppable-area') return;

            if (active && over) {
                const index = data.findIndex((el) => el.fieldname === active.id);
                if (index === -1) {
                    data = [
                        {
                            label: active.data.current.label,
                            fieldname: active.id,
                            section: active.data.current.section
                        },
                        ...data
                    ];
                    setMandatoryFields(data);
                }
            }
        };
        function isEmpty() {
            if (champsVisible?.length === 0) return true;

            const mandatoryFieldNames = visibleMandatoryFields?.map((field) => field?.fieldname);

            return champsVisible?.every((field) => mandatoryFieldNames?.includes(field?.fieldname));
        }
        return (
            <DndContext sensors={sensors} collisionDetection={rectIntersection} onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 16, width: '100%' }}>
                    <PerfectScrollbar style={{ width: '50%', overflowX: 'hidden', height: 400 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                            {isEmpty() && (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 400 }}>
                                    <WidgetsIcon color="disabled" sx={{ height: 40, width: 40 }} />
                                </div>
                            )}
                            {champsVisible?.map((field, i) => (
                                <React.Fragment key={i}>
                                    {visibleMandatoryFields.findIndex((el) => el.fieldname === field.fieldname) < 0 && (
                                        <DraggableChip
                                            key={field.fieldname}
                                            fieldname={field.fieldname}
                                            section={field.section}
                                            label={field.label}
                                        />
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </PerfectScrollbar>
                    <PerfectScrollbar style={{ width: '50%', overflowX: 'hidden', height: 400 }}>
                        <DroppableContainer>
                            {visibleMandatoryFields?.map((field, i) => (
                                <Chip
                                    onDelete={
                                        ['item_name', 'stock_uom', 'item_group', 'item_code', 'attributes'].includes(field.fieldname)
                                            ? null
                                            : () => {
                                                  setMandatoryFields(mandatoryFields.filter((el) => el.fieldname !== field.fieldname));
                                              }
                                    }
                                    key={i}
                                    size="medium"
                                    clickable
                                    label={t(`products:${field.label.replace(/\s/g, '')}`)}
                                    sx={{
                                        backgroundColor: theme.palette.secondary.main,
                                        color: theme.palette.primary.main,
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        borderRadius: 1,
                                        fontSize: 14,
                                        fontWeight: 700,
                                        '&:active': {
                                            boxShadow: 0
                                        },
                                        '&:hover': {
                                            background: theme.palette.secondary.light
                                        }
                                    }}
                                />
                            ))}
                        </DroppableContainer>
                    </PerfectScrollbar>
                    <DragOverlay dropAnimation={defaultDropAnimation}>
                        {activeField ? (
                            <Chip
                                size="medium"
                                label={t(activeField.label)}
                                clickable
                                icon={<DragIndicatorIcon color="secondary" />}
                                sx={{
                                    backgroundColor: theme.palette.secondary.main,
                                    color: theme.palette.blue.main,
                                    justifyContent: 'flex-start',
                                    width: '100%',
                                    borderRadius: 1,
                                    fontSize: 14,
                                    fontWeight: 500,
                                    '&:active': {
                                        boxShadow: 0
                                    },
                                    '&:hover': {
                                        background: theme.palette.secondary.light
                                    }
                                }}
                            />
                        ) : null}
                    </DragOverlay>
                </div>
            </DndContext>
        );
    };

    useEffect(() => {
        if (mandatoryFieldsInLara?.[0]?.sections) {
            setSections(JSON.parse(mandatoryFieldsInLara?.[0]?.sections));
        } else {
            // setSections(itemSections);
        }
        if (mandatoryFieldsInLara?.[0]?.product_required_fields) {
            setMandatoryFields(JSON.parse(mandatoryFieldsInLara?.[0]?.product_required_fields));
        } else {
            setMandatoryFields(itemFields?.filter((el) => el?.section === 'basic_section'));
        }
    }, [mandatoryFieldsInLara]);

    if (isPending) return <Loader />;

    return (
        <>
            <MainCard title={title} contentSX={{ backgroundColor: 'Menu' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <SortableChips />
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} lg={8}>
                        <DraggableChips />
                    </Grid>
                </Grid>
            </MainCard>
            <Box display={'flex'} justifyContent={'right'} mt={2}>
                <LoadingButton
                    loading={loadingState}
                    onClick={handleSaveMandatoryFields}
                    variant="contained"
                    color="blue"
                    size="large"
                    disabled={mandatoryFields?.length == 9}
                >
                    {t('common:save')}
                </LoadingButton>
            </Box>

            <Modal onClose={handleClose} open={open}>
                {addCustomField}
            </Modal>
        </>
    );
};

export default CustomizeFormItem;
