import React from 'react';
import { Grid, Box, Button } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import InputTextField from './InputTextField';
import InputFileField from './InputFileField';
import AutoCompleteField from './AutoCompleteField';
import { useNavigate } from 'react-router';
import SelectForm from './SelectForm';
import AddItem from './addItem';
import { LoadingButton } from '@mui/lab';
import InputCheckboxField from './InputCheckboxField';
import { useTranslation } from 'react-i18next';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Link } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';

const GeneralForm = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const theme = useTheme();
    const { t } = useTranslation();

    const {
        isadd,
        ismodal,
        loadingButton,
        state,
        label,
        setState,
        error,
        setError,
        handleSubmit,
        currencies,
        countries,
        chart_Of_Accounts_Template_List,
        chart_Of_Accounts_List,
        companies,
        sellingpricelists,
        warehouses,
        parentwarehouses,
        paymentmethods,
        itemgroups,
        pathimage,
        default_bank_account,
        setOpen,
        customers,
        nameAlreadyExist,
        sellers
    } = props;
    const handleChange = (event) => {
        setError({ ...error, [event.target.name]: false });
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const handleChangeAutoComplete = (value, field) => {
        setError({ ...error, [field]: false });
        setState({ ...state, [field]: value });
    };
    const handleChangeSelect = (event) => {
        setError({ ...error, [event.target.name]: false });
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const handleChangeSelectmultiple = (event) => {
        setError({ ...error, [event.target.name]: false });
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const handleChangeCheckbox = (event, value) => {
        setError({ ...error, [event.target.name]: false });
        setState({ ...state, [event.target.name]: value ? 1 : 0 });
    };
    const handleFilesChange = (files) => {
        setError({ ...error, image: false });
        setState({ ...state, image: files });
    };
    return (
        <Box>
            <Grid container rowSpacing={2}>
                {label?.image && (
                    <InputFileField
                        label={label?.image}
                        value={state?.image}
                        error={error.image}
                        pathimage={pathimage}
                        name="image"
                        handleChange={handleFilesChange}
                    />
                )}
                {label?.is_principal && (
                    <InputCheckboxField
                        label={label?.is_principal}
                        value={state?.is_principal === 1 ? true : false}
                        error={error.is_principal}
                        name="is_principal"
                        handleChange={handleChangeCheckbox}
                    />
                )}
                {label?.code && (
                    <InputTextField
                        required
                        fullwidth
                        label={label?.code}
                        value={state?.code}
                        error={error.code}
                        name="code"
                        handleChange={handleChange}
                    />
                )}
                {label?.name && isadd && (
                    <InputTextField
                        required
                        label={label?.name}
                        value={state?.name}
                        error={error.name || nameAlreadyExist}
                        name="name"
                        fullwidth={ismodal ? true : false}
                        handleChange={handleChange}
                        helper={nameAlreadyExist ? 'Existe déjà' : null}
                    />
                )}
                {label?.pos_code && (
                    <InputTextField
                        required
                        label={label?.pos_code}
                        value={state?.pos_code}
                        error={error.pos_code || nameAlreadyExist}
                        name="pos_code"
                        fullwidth={ismodal ? true : false}
                        handleChange={handleChange}
                    />
                )}
                {label?.abbreviation && (
                    <InputTextField
                        required
                        label={label?.abbreviation}
                        value={state?.abbreviation}
                        error={error.abbreviation}
                        name="abbreviation"
                        handleChange={handleChange}
                    />
                )}
                {label?.company && (
                    <AutoCompleteField
                        sx={{ width: '70%' }}
                        label={label?.company}
                        value={state?.company}
                        error={error.company}
                        options={companies}
                        name="company"
                        handleChange={handleChangeAutoComplete}
                    />
                )}
                {label?.selling_price_list && (
                    <AutoCompleteField
                        sx={{ width: '70%' }}
                        label={label?.selling_price_list}
                        value={state?.selling_price_list}
                        error={error.selling_price_list}
                        options={sellingpricelists}
                        name="selling_price_list"
                        handleChange={handleChangeAutoComplete}
                    />
                )}
                {state.selling_price_list && (
                    <Link to={`/pim/edit-tarif/${state.selling_price_list.name}`} style={{ margin: '20px' }} target="_blank">
                        <ModeEditIcon aria-label="edit selling_price list" size="small">
                            <ModeEditIcon fontSize="small" />
                        </ModeEditIcon>
                    </Link>
                )}
                {label?.parent_warehouse && (
                    <AutoCompleteField
                        sx={{ width: '70%' }}
                        label={label?.parent_warehouse}
                        value={state?.parent_warehouse}
                        error={error.parent_warehouse}
                        options={parentwarehouses}
                        name="parent_warehouse"
                        handleChange={handleChangeAutoComplete}
                    />
                )}
                {label?.warehouse && (
                    <AutoCompleteField
                        sx={{ width: '70%' }}
                        label={label?.warehouse}
                        value={state?.warehouse}
                        error={error.warehouse}
                        options={warehouses}
                        name="warehouse"
                        handleChange={handleChangeAutoComplete}
                        disabled={!state.parent_warehouse}
                    />
                )}
                {label?.itemgroup && (
                    <AutoCompleteField
                        sx={{ width: '70%' }}
                        label={label?.itemgroup}
                        value={state?.itemgroup}
                        error={error.itemgroup}
                        options={itemgroups}
                        name="itemgroup"
                        fullwidth
                        handleChange={handleChangeAutoComplete}
                    />
                )}
                {label?.selling_rate && (
                    <InputTextField
                        required
                        fullwidth
                        label={label?.selling_rate}
                        value={state?.selling_rate}
                        error={error.selling_rate}
                        name="selling_rate"
                        handleChange={handleChange}
                    />
                )}
                {label?.country && (
                    <AutoCompleteField
                        sx={{ width: '70%' }}
                        label={label?.country}
                        value={state?.country}
                        error={error.country}
                        options={countries}
                        name="country"
                        handleChange={handleChangeAutoComplete}
                    />
                )}
                {label?.currency && (
                    <AutoCompleteField
                        sx={{ width: '70%' }}
                        label={label?.currency}
                        value={state?.currency}
                        error={error.currency}
                        options={currencies}
                        name="currency"
                        handleChange={handleChangeAutoComplete}
                    />
                )}
                {label?.currencies && (
                    <AddItem
                        sx={{ width: '70%' }}
                        label={label?.currencies}
                        name="currencies"
                        value={state?.currencies}
                        propToRender={'name'}
                        handleChange={handleChangeSelectmultiple}
                        data={currencies ?? currencies}
                    />
                )}
                {label?.paymentmode && (
                    <AddItem
                        sx={{ width: '70%' }}
                        required
                        label={label?.paymentmode}
                        name="paymentmode"
                        value={state?.paymentmode}
                        error={error.paymentmode}
                        propToRender={'name'}
                        handleChange={handleChangeSelectmultiple}
                        defaultItem={state?.defaultItem}
                        data={paymentmethods ?? paymentmethods}
                    />
                )}
                {label?.price_list && (
                    <AutoCompleteField
                        sx={{ width: '70%' }}
                        label={label?.price_list}
                        value={state?.price_list}
                        error={error.price_list}
                        options={sellingpricelists}
                        name="price_list"
                        handleChange={handleChangeAutoComplete}
                    />
                )}
                {label?.seller && (
                    <AutoCompleteField
                        sx={{ width: '70%' }}
                        label={label?.seller}
                        value={state?.seller}
                        error={error.seller}
                        options={sellers}
                        name="seller"
                        handleChange={handleChangeAutoComplete}
                        required={false}
                    />
                )}
                {label?.default_customer && (
                    <AutoCompleteField
                        sx={{ width: '70%' }}
                        label={label?.default_customer}
                        value={state?.default_customer}
                        error={error.default_customer}
                        options={customers}
                        name="default_customer"
                        handleChange={handleChangeAutoComplete}
                    />
                )}
                {label?.terminal && (
                    <InputTextField
                        label={label?.terminal}
                        value={state?.terminal}
                        error={error.terminal}
                        name="terminal"
                        handleChange={handleChange}
                    />
                )}
                {label?.email && (
                    <InputTextField
                        label={label?.email}
                        value={state?.email}
                        error={error.email}
                        name="email"
                        handleChange={handleChange}
                    />
                )}
                {label?.phone_no && (
                    <InputTextField
                        label={label?.phone_no}
                        value={state?.phone_no}
                        error={error.phone_no}
                        name="phone_no"
                        handleChange={handleChange}
                    />
                )}
                {label?.web_site && (
                    <InputTextField
                        label={label?.web_site}
                        value={state?.web_site}
                        error={error.web_site}
                        name="web_site"
                        handleChange={handleChange}
                    />
                )}
                {label?.bank_name && (
                    <InputTextField
                        label={label?.bank_name}
                        value={state?.bank_name}
                        error={error.bank_name}
                        name="bank_name"
                        handleChange={handleChange}
                    />
                )}
                {label?.default_bank_account && (
                    <SelectForm
                        sx={{ width: '70%' }}
                        label={label?.default_bank_account}
                        name="default_bank_account"
                        value={state?.default_bank_account}
                        handleChange={handleChangeSelect}
                        propToRender={'name'}
                        data={default_bank_account ?? default_bank_account}
                    />
                )}
                {label?.year && (
                    <InputTextField
                        type={'number'}
                        label={label?.year}
                        value={state?.year}
                        error={error.year}
                        name="year"
                        handleChange={handleChange}
                    />
                )}
                {label?.finance_book && (
                    <InputTextField
                        label={label?.finance_book}
                        value={state?.finance_book}
                        error={error.finance_book}
                        name="finance_book"
                        handleChange={handleChange}
                    />
                )}
                {label?.description && (
                    <InputTextField
                        label={label?.description}
                        value={state?.description}
                        error={error.description}
                        name="description"
                        handleChange={handleChange}
                    />
                )}
                {label?.chart_Of_Accounts && (
                    <SelectForm
                        sx={{ width: '70%' }}
                        label={label?.chart_Of_Accounts}
                        name="chart_Of_Accounts"
                        value={state?.chart_Of_Accounts}
                        handleChange={handleChangeSelect}
                        propToRender={'name'}
                        data={chart_Of_Accounts_List ?? chart_Of_Accounts_List}
                    />
                )}
                {label?.chart_Of_Accounts_Template && state?.chart_Of_Accounts === 'Standard Template' && (
                    <SelectForm
                        sx={{ width: '70%' }}
                        label={label?.chart_Of_Accounts_Template}
                        name="chart_Of_Accounts_Template"
                        value={state?.chart_Of_Accounts_Template}
                        handleChange={handleChangeSelect}
                        propToRender={'name'}
                        data={chart_Of_Accounts_Template_List ?? chart_Of_Accounts_Template_List}
                    />
                )}
                {label?.existing_company && state?.chart_Of_Accounts === 'Existing Company' && (
                    <AutoCompleteField
                        sx={{ width: '70%' }}
                        label={label?.existing_company}
                        value={state?.existing_Company}
                        error={error.currency}
                        options={companies}
                        name="existing_company"
                        handleChange={handleChangeAutoComplete}
                    />
                )}
                {label?.Logo && (
                    <InputTextField label={label?.Logo} value={state?.Logo} error={error.Logo} name="Logo" handleChange={handleChange} />
                )}
            </Grid>
            <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                <Button sx={{ mr: 1 }} onClick={() => (ismodal ? setOpen(false) : navigate(-1))}>
                    {t('common:cancel')}
                </Button>
                <LoadingButton
                    onClick={handleSubmit}
                    loading={loadingButton}
                    size="large"
                    variant="contained"
                    color="blue"
                    disabled={nameAlreadyExist}
                >
                    {t('common:save')}
                </LoadingButton>
            </Box>
        </Box>
    );
};

export default GeneralForm;
