import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
//import TemplateProdForm from './TemplateProdForm';
import GenericTemplateProdForm from './GenericTemplateProdForm';
import useFetch from 'hooks/useFetch';
import Loader from 'ui-component/Loader';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const GenericProducDetails = ({ doc, isTemplate, editToggled, storedFiles, refetchFiles }) => {
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const { t } = useTranslation();

    // formatting the Request DATA
    // >>>
    const itemGroupsReqData = {
        doctype: 'Item Group',
        fields: ['`tabItem Group`.`name`'],
        filters: [['Item Group', 'is_group', '=', 1]],
        start: 0,
        page_length: 100
    };

    const brandReqData = {
        doctype: 'Brand',
        fields: ['`tabBrand`.`name`'],
        start: 0,
        page_length: 50
    };

    const itemTaxReqData = {
        doctype: 'Item Tax Template',
        fields: ['`tabItem Tax Template`.`name`'],
        filters: [['Item Tax Template', 'company', '=', globalDefaults.default_company]],
        start: 0,
        page_length: 20
    };

    const itemAttributesReqData = {
        doctype: 'Item Attribute',
        fields: ['`tabItem Attribute`.`name`'],
        start: 0,
        page_length: 200
    };

    const companiesReqData = {
        doctype: 'Company',
        fields: ['`tabCompany`.`name`'],
        start: 0,
        page_length: 20
    };

    const priceListsReqData = {
        doctype: 'Price List',
        fields: ['`tabPrice List`.`name`'],
        filters: [['Price List', 'enabled', '=', 1]],
        start: 0,
        page_length: 20
    };

    const warehouseReqData = {
        doctype: 'Warehouse',
        fields: ['`tabWarehouse`.`name`', '`tabWarehouse`.`company`'],
        filters: [['Warehouse', 'company', '=', globalDefaults.default_company]],
        start: 0,
        page_length: 50
    };

    const itemCollectionReqData = {
        doctype: 'Item Collection',
        fields: ['`tabItem Collection`.`name`'],
        start: 0,
        page_length: 50
    };

    const itemUOMReqData = {
        doctype: 'UOM',
        fields: ['`tabUOM`.`name`'],
        start: 0,
        page_length: 20
    };

    const supplierReqData = {
        doctype: 'Supplier',
        fields: ['`tabSupplier`.`name`'],
        start: 0,
        page_length: 50
    };
    const tablelibreReqData = {
        doctype: 'Table Libre',
        fields: ['`tabTable Libre`.`name`', '`tabTable Libre`.`table_code`', '`tabTable Libre`.`libelle`']
    };

    const currencyReqData = {
        doctype: 'Currency',
        fields: ['`tabCurrency`.`name`'],
        start: 0,
        page_length: 20
    };

    const sousfamilleReqData = {
        doctype: 'SousFamille',
        fields: ['`tabSousFamille`.`name`', '`tabSousFamille`.`famille`', '`tabSousFamille`.`libelle`'],
        start: 0,
        page_length: 20
    };
    const souslibreReqData = {
        doctype: 'SousTable Libre',
        fields: ['`tabSousTable Libre`.`name`', '`tabSousTable Libre`.`table_libre`'],
        start: 0,
        page_length: 500
    };

    // fetching DATA
    // >>>
    const { data: itemGroups, isPending } = useFetch(`/api/get-list`, itemGroupsReqData, 'POST');
    const { data: companies, isCompaniesPending } = useFetch(`/api/get-list`, companiesReqData, 'POST');
    const { data: warehouses, isWarehousesPending } = useFetch(`/api/get-list`, warehouseReqData, 'POST');
    const { data: brands, isBrandsPending } = useFetch(`/api/get-list`, brandReqData, 'POST');
    const { data: itemTaxTemplates, isTaxesPending } = useFetch(`/api/get-list`, itemTaxReqData, 'POST');
    const { data: priceLists, isPriceListsPending } = useFetch(`/api/get-list`, priceListsReqData, 'POST');
    const { data: itemAttributes, isAttributesPending } = useFetch(`/api/get-list`, itemAttributesReqData, 'POST');
    const { data: itemCollections, isitemCollectionsPending } = useFetch(`/api/get-list`, itemCollectionReqData, 'POST');
    const { data: currencyLists, isCurrencyPending } = useFetch(`/api/get-list`, currencyReqData, 'POST');
    const { data: sousfamilleLists, isSousFamillePending } = useFetch(`/api/get-list`, sousfamilleReqData, 'POST');
    const { data: uoms, isUomsPending } = useFetch(`/api/get-list`, itemUOMReqData, 'POST');
    const { data: suppliers, isSuppliersPending } = useFetch(`/api/get-list`, supplierReqData, 'POST');
    const { data: tablelibreLists, isTableLibreListsPending } = useFetch('/api/get-list', tablelibreReqData, 'POST');
    const { data: soustablelibreLists, isSousTableLibreListsPending } = useFetch('/api/get-list', souslibreReqData, 'POST');

    if (
        isPriceListsPending ||
        isAttributesPending ||
        isTaxesPending ||
        isBrandsPending ||
        isWarehousesPending ||
        isCompaniesPending ||
        isitemCollectionsPending ||
        isUomsPending ||
        isSuppliersPending ||
        isPending ||
        isTableLibreListsPending ||
        isSousTableLibreListsPending ||
        isSousFamillePending ||
        isCurrencyPending ||
        isPending
    )
        return <Loader />;

    return (
        <div>
            {doc?.disabled === 1 ? (
                <Box display={'flex'} mt={-4} mb={2}>
                    <InfoIcon sx={{ mt: 0.6 }} color="error" />
                    <Typography p={1} variant="h5" color="error">
                        {t('products:activeMsg')}
                    </Typography>
                </Box>
            ) : (
                isTemplate && (
                    <Box display={'flex'} mt={-4} mb={2}>
                        <InfoIcon sx={{ mt: 0.6 }} />
                        <Typography p={1} variant="h5" color="primary">
                            {t('products:modelMsg')}
                        </Typography>
                    </Box>
                )
            )}
            <Box p={5} sx={{ borderRadius: 2, bgcolor: 'Menu' }}>
                <GenericTemplateProdForm
                    doc={doc}
                    isSimple={!isTemplate}
                    editToggled={editToggled}
                    itemGroups={itemGroups}
                    companies={companies}
                    warehouses={warehouses}
                    tablelibreLists={tablelibreLists}
                    sousfamilleLists={sousfamilleLists}
                    currencyLists={currencyLists}
                    brands={brands}
                    itemTaxTemplates={itemTaxTemplates}
                    itemAttributes={itemAttributes}
                    priceLists={priceLists}
                    storedFiles={storedFiles}
                    soustablelibreLists={soustablelibreLists}
                    itemCollections={itemCollections}
                    uoms={uoms}
                    suppliers={suppliers}
                    refetchFiles={refetchFiles}
                />
            </Box>
        </div>
    );
};

export default GenericProducDetails;
