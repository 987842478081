import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/system';
import useFetch from 'hooks/useFetch';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import InputTextField from 'ui-component/form/InputTextField';
import SelectForm from 'ui-component/form/SelectForm';
import { useStyles } from 'views/utilities/Style';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const AddCategoryForm = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const itemGroupsReqData = {
        doctype: 'Item Group',
        fields: ['`tabItem Group`.`name`'],
        filters: [['Item Group', 'is_group', '=', 1]],
        start: 0,
        page_length: 500
    };

    const { data: itemGroups, isPending } = useFetch(`/api/get-list`, itemGroupsReqData, 'POST');

    const [isGroup, setIsGroup] = useState(false);
    const [itemGroupName, setItemGroupName] = useState('');
    const [itemGroupCode, setItemGroupCode] = useState('');
    const [parent, setParent] = useState('');
    const [loadingButton, setLoadingButton] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'itemGroupName') {
            setItemGroupName(value);
        } else if (name === 'itemGroupCode') {
            setItemGroupCode(value);
        }
    };

    const handleSelectChange = (event) => {
        const {
            target: { value }
        } = event;
        setParent(value);
    };

    const newItemGroupData = {
        doctype: 'Item Group',
        is_group: isGroup ? 1 : 0,
        item_group_name: itemGroupName,
        item_group_code: itemGroupCode,
        parent: parent,
        is_root: false
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleAddItemGroup = async () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/products/add-category`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(newItemGroupData)
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    throw Error('Something went wrong');
                }
                return res.json();
            })
            .then((data) => {
                dispatch(SetNotification({ code: 'success', message: t('common:addCatSuccess') }));
                navigate('/pim/products-category-list-view');
            });
    };

    return (
        <Box>
            <MainCard
                title={
                    <Box display={'flex'} flexDirection={'column'}>
                        <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
                        {t('common:newCat')}
                    </Box>
                }
            >
                <Box>
                    <FormControlLabel
                        label={t('common:group')}
                        control={<Checkbox color="blue" checked={isGroup} onChange={() => setIsGroup(!isGroup)} />}
                    />
                    <Typography id="modal-modal-description" sx={{ fontSize: 11 }}>
                        {t('products:msg1')}
                    </Typography>
                    <InputTextField
                        sx={{ mt: 0 }}
                        error={itemGroupCode === ''}
                        handleChange={handleChange}
                        value={itemGroupCode}
                        required
                        label={t('products:categoryCode')}
                        id="outlined-size-small"
                        size="small"
                        name="itemGroupCode"
                    />
                    <InputTextField
                        sx={{ mt: 0 }}
                        error={itemGroupName === ''}
                        handleChange={handleChange}
                        value={itemGroupName}
                        required
                        label={t('products:categoryName')}
                        id="outlined-size-small"
                        size="small"
                        name="itemGroupName"
                    />
                    <SelectForm
                        sx={{ mt: -2 }}
                        label={t('common:parent')}
                        value={parent}
                        handleChange={handleSelectChange}
                        data={itemGroups}
                        propToRender={'name'}
                        disabled={isGroup}
                    />
                </Box>
                <Grid container xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Grid item xs={12}>
                        <Box display={'flex'} justifyContent={'center'} mt={4}>
                            <Button sx={{ mr: 1 }} onClick={() => navigate(-1)}>
                                {t('common:cancel')}
                            </Button>
                            <LoadingButton
                                disabled={itemGroupName === '' || itemGroupCode === ''}
                                onClick={handleAddItemGroup}
                                size="large"
                                variant="contained"
                                color="blue"
                                loading={loadingButton}
                            >
                                {t('common:create')}
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </MainCard>
        </Box>
    );
};

export default AddCategoryForm;
