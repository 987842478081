import React from 'react';
import { FormControl, InputLabel, Grid } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const AutoCompleteField = (props) => {
    const classes = useStyles();
    const { handleChange, label, value, name, error, options, fullwidth, required = true, disabled = false } = props;

    return (
        <Grid
            container
            item
            xs={!fullwidth && 12}
            sm={!fullwidth && 12}
            md={!fullwidth && 8}
            lg={!fullwidth && 8}
            xl={!fullwidth && 8}
            spacing={4}
            display={'flex'}
            alignItems={'center'}
        >
            <Grid item xs={4}>
                <InputLabel error={error ?? error} required={required} className={classes.inputLabel}>
                    {label}
                </InputLabel>
            </Grid>
            <Grid item xs={8}>
                <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                        id="outlined-adornment-weight"
                        onChange={(event, newValue) => {
                            if (event) {
                                handleChange(newValue, name);
                            }
                        }} /* 
                        onInputChange={(event, newInputValue) => {}} */
                        value={value || null}
                        /*   error={error ?? error} */
                        name={name}
                        options={Array.isArray(options) ? options : []}
                        getOptionLabel={(option) =>
                            option.title
                                ? option.title
                                : option.name
                                ? option.name
                                : option.label
                                ? option.label
                                : option?.sales_person_name
                                ? option?.sales_person_name
                                : ''
                        }
                        renderInput={(params) => <TextField error={error ?? error} {...params} />}
                        disabled={disabled}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default AutoCompleteField;
