import { Box, Button, Grid, IconButton, OutlinedInput, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import SelectForm from 'ui-component/form/SelectForm';
import { CurrencyFormatter, formatErpResponse } from 'utils/utils';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { SetNotification } from 'store/services/api';
import { t } from 'i18next';

function ItemPriceForm({ setItemPriceState, itemPriceState, doc, disabled, priceLists }) {
    const dispatch = useDispatch();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const [open, setOpen] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [taxRate, setTaxRate] = useState(null);

    useEffect(() => {
        const fetchTaxRate = async () => {
            if (doc?.item_tax_category) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
                            Authorization: JSON.parse(localStorage.getItem('user'))?.token
                        },
                        body: JSON.stringify({
                            doctype: 'Item Tax Template Detail',
                            fields: ['tax_rate'],
                            filters: [['Item Tax Template Detail', 'parent', '=', doc.item_tax_category]]
                        })
                    });
                    const data = await response.json();
                    if (data && data.length > 0) {
                        setTaxRate(parseFloat(data[0].tax_rate));
                    }
                } catch (error) {
                    console.error('error fetching tax:', error);
                }
            }
        };

        fetchTaxRate();
    }, [doc?.item_tax_category]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setRow2Insert({
            doctype: 'Item Price',
            price_list: '',
            price_list_rate: '',
            item_price_ttc: '',
            item_code: doc.name,
            currency: globalDefaults?.default_currency
        });
    };

    const [row2Insert, setRow2Insert] = useState({
        doctype: 'Item Price',
        price_list: '',
        price_list_rate: '',
        item_price_ttc: '',
        item_code: doc.name,
        currency: globalDefaults?.default_currency
    });

    const formatDecimal = (num) => {
        if (!num || num === '') return '';
        return Number(parseFloat(num).toFixed(3));
    };

    const calculateTTC = (ht) => {
        if (!ht || ht === '') return '';
        if (!taxRate) return formatDecimal(ht);
        return formatDecimal(parseFloat(ht) * (1 + taxRate / 100));
    };

    const calculateHT = (ttc) => {
        if (!ttc || ttc === '') return '';
        if (!taxRate) return formatDecimal(ttc);
        return formatDecimal(parseFloat(ttc) / (1 + taxRate / 100));
    };
    const handleChangeValues = (e) => {
        const { name, value } = e.target;
        const validateInput = (input) => {
            if (input === '') return '';
            const regex = /^\d*\.?\d{0,3}$/;
            if (regex.test(input)) {
                return input;
            }
            return row2Insert[name];
        };

        if (name === 'price_list_rate') {
            const validatedValue = validateInput(value);
            const ttc = calculateTTC(validatedValue);
            setRow2Insert({
                ...row2Insert,
                price_list_rate: validatedValue,
                item_price_ttc: ttc
            });
        } else if (name === 'item_price_ttc') {
            const validatedValue = validateInput(value);
            const ht = calculateHT(validatedValue);
            setRow2Insert({
                ...row2Insert,
                item_price_ttc: validatedValue,
                price_list_rate: ht
            });
        } else {
            setRow2Insert({ ...row2Insert, [name]: value });
        }
    };

    const handleInsert = () => {
        const parsedRate = row2Insert.price_list_rate ? formatDecimal(row2Insert.price_list_rate) : 0;
        const ttc = calculateTTC(parsedRate);
        const newRow = {
            ...row2Insert,
            price_list_rate: parsedRate,
            item_price_ttc: ttc,
            __islocal: 1,
            __unsaved: 1
        };
        setItemPriceState([...itemPriceState, newRow]);
        handleClose();
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleSubmit = async () => {
        setLoadingButton(true);

        const filteredDocs = itemPriceState
            ?.filter((doc) => doc?.doctype === 'Item Price')
            .map((doc) => ({
                ...doc,
                doctype: 'Item Price',
                __islocal: 1,
                __unsaved: 1,
                item_code: doc.item_code,
                price_list: doc.price_list,
                price_list_rate: doc.price_list_rate,
                item_price_ttc: doc.item_price_ttc || calculateTTC(doc.price_list_rate)
            }));

        const requests = filteredDocs?.map((doc) =>
            fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({
                    doc: doc,
                    action: 'Save'
                })
            })
        );

        Promise.all(requests)
            .then((responses) => Promise.all(responses.map((res) => res.json())))
            .then((dataArr) => {
                setLoadingButton(false);
                dataArr.forEach((data) => {
                    if (data?.success === false) {
                        dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                    } else {
                        dispatch(SetNotification({ code: 'success', message: t('products:price_saved_succesfully') }));
                    }
                });
            });
    };

    const handleDelete = (el) => {
        setItemPriceState(
            itemPriceState.filter((row) => !(row.price_list === el.price_list && row.price_list_rate === el.price_list_rate))
        );
    };
    return (
        <div style={{ padding: 20 }}>
            <Box>
                <Grid container justifyContent={'space-between'} display={'flex'} alignItems={'center'}>
                    <Typography fontWeight={600} variant="h4">
                        {t('products:newitemP')}
                    </Typography>
                    <Button color="blue" onClick={handleOpen} disabled={disabled}>
                        <AddIcon sx={{ marginLeft: -1 }} /> {t('core:add')}
                    </Button>
                </Grid>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>{t('columns:price_list_name')}</strong>
                            </TableCell>
                            <TableCell>
                                <strong>{t('products:price_list_rate')}</strong>
                            </TableCell>
                            <TableCell>
                                <strong>{t('products:price_ttc')}</strong>
                            </TableCell>
                            <TableCell align="right" width={50}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {itemPriceState?.map((el, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    <strong>{el?.price_list}</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>
                                        <CurrencyFormatter
                                            locale="fr-FR"
                                            currency={el?.currency}
                                            minimumFractionDigits={3}
                                            value={el?.price_list_rate}
                                        />
                                    </strong>
                                </TableCell>
                                <TableCell>
                                    <strong>
                                        <CurrencyFormatter
                                            locale="fr-FR"
                                            currency={el?.currency}
                                            minimumFractionDigits={3}
                                            value={el?.item_price_ttc || calculateTTC(el?.price_list_rate)}
                                        />
                                    </strong>
                                </TableCell>
                                <TableCell align="right" width={50}>
                                    <IconButton disabled={disabled} onClick={() => handleDelete(el)}>
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        {(open || itemPriceState?.length === 0) && !disabled && (
                            <TableRow key={999}>
                                <TableCell>
                                    <SelectForm
                                        fullwidth
                                        handleChange={handleChangeValues}
                                        value={row2Insert.price_list}
                                        name="price_list"
                                        data={priceLists}
                                        propToRender={'name'}
                                    />
                                </TableCell>
                                <TableCell>
                                    <OutlinedInput
                                        type="number"
                                        value={row2Insert.price_list_rate}
                                        onChange={handleChangeValues}
                                        name="price_list_rate"
                                        placeholder={t('products:price_ht')}
                                        inputProps={{
                                            min: 0,
                                            step: '0.001'
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <OutlinedInput
                                        type="number"
                                        value={row2Insert.item_price_ttc}
                                        onChange={handleChangeValues}
                                        name="item_price_ttc"
                                        placeholder={t('products:price_ttc')}
                                        inputProps={{
                                            min: 0,
                                            step: '0.001'
                                        }}
                                    />
                                </TableCell>
                                <TableCell align="right" width={100}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <IconButton onClick={handleInsert} disabled={!row2Insert.price_list}>
                                            <DoneIcon color={!row2Insert.price_list ? 'disabled' : ''} />
                                        </IconButton>
                                        <IconButton onClick={handleClose}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {itemPriceState?.filter((doc) => doc?.doctype === 'Item Price')?.length > 0 ? (
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        <LoadingButton variant="contained" color="blue" loading={loadingButton} onClick={handleSubmit} disabled={disabled}>
                            {t('columns:save')}
                        </LoadingButton>
                    </Box>
                ) : null}
            </Box>
        </div>
    );
}

export default ItemPriceForm;
