import useGetAxios from 'hooks/useGetAxios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import { useStyles } from 'views/utilities/Style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import { Box } from '@mui/system';
import { Button, Chip, Divider, Grid, IconButton, Modal, Typography } from '@mui/material';
import Loader from 'ui-component/Loader';
import useFetch from 'hooks/useFetch';
import moment from 'moment';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import SelectForm from 'ui-component/form/SelectForm';
import ProductTable from 'ui-component/tables/ProductTable';
import InputTextField from 'ui-component/form/InputTextField';
import PersonIcon from '@mui/icons-material/Person';
import { LoadingButton } from '@mui/lab';
import { formatErpResponse } from 'utils/utils';
import { SetMappedDeliveryNote, SetMappedSalesInvoice, SetNotification } from 'store/services/api';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import PrintInvoiceTemplate from './PrintInvoiceTemplate';
import PaidIcon from '@mui/icons-material/Paid';
import { modalStyle } from 'views/utilities/ModalStyle';
import SalesInvoicePayment from './SalesInvoicePayment';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import SalesInvoiceReturn from './SalesInvoiceReturn';
import EmailSender from 'ui-component/emailSender/EmailSender';
import BundleItemsTable from 'views/products/productBundle/BundleItemsTable';
import ReceiptIcon from '@mui/icons-material/Receipt';
import html2pdf from 'html2pdf.js';
import { useBlocker } from 'hooks/useBlocker';
import NavigationModal from 'ui-component/cards/NavigationModal';
import Comments from 'ui-component/comments/Comments';
import { useTranslation } from 'react-i18next';
import { statusFormat } from 'helpers/utils';

const SalesInvoiceForm = () => {
    const classes = useStyles();
    const componentRef = useRef();
    const { id } = useParams();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const { t } = useTranslation();

    const [formIsDirty, setFormIsDirty] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    useBlocker(({ retry }) => {
        setModalOpen(true);
    }, formIsDirty);

    const handleCloseBlocker = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setModalOpen(false);
        setFormIsDirty(false);
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const mappedSalesInvoice = useSelector((state) => state.mappedDocs.mappedSalesInvoice);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setMappedDoc(null);
    };

    const [docMakingloadingButton, setDocMakingloadingButton] = useState(false);
    const [returnloadingButton, setReturnloadingButton] = useState(false);
    const [mappedDeliveryNoteLoadingButton, setMappedDeliveryNoteLoadingButton] = useState(false);

    const [mappedDoc, setMappedDoc] = useState(null);

    const [company, setCompany] = useState(globalDefaults?.default_company);

    const {
        data: doc,
        isPending,
        refetch: refetchDoc,
        docinfo
    } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Sales%20Invoice&name=${id}`);
    const {
        data: docCounts,
        isDocCountsPending,
        refetch: refetchCount
    } = useGetAxios(
        `${process.env.REACT_APP_API_URI}/api/order/doc-numbers?doctype=Sales%20Invoice&name=${id}&items=%5B%22Payment%20Entry%22%2C%22Delivery%20Note%22%5D`,
        null,
        true
    );

    const { data: currencies, isPending: isCurrenciesPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Currency', fields: ['`tabCurrency`.`name`'], filters: [['Currency', 'enabled', '=', 1]] },
        'POST'
    );

    const { data: warehouses, isPending: isWarehousesPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Warehouse',
            fields: ['`tabWarehouse`.`name`'],
            filters: [
                ['Warehouse', 'is_group', '=', 0],
                ['Warehouse', 'company', '=', company]
            ]
        },
        'POST'
    );

    const { data: sellingPriceLists, isPending: isSellingPriceListsPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Price List',
            fields: ['`tabPrice List`.`name`'],
            filters: [
                ['Price List', 'selling', '=', 1],
                ['Price List', 'name', 'not like', '%ignore']
            ]
        },
        'POST'
    );

    const { data: taxesAndCharges, isPending: isTaxesAndChargesPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Sales Taxes and Charges Template',
            fields: ['`tabSales Taxes and Charges Template`.`name`'],
            filters: [['Sales Taxes and Charges Template', 'company', '=', company]]
        },
        'POST'
    );

    const {
        data: customers,
        isPending: isCustomersPending,
        refetch
    } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Customer',
            fields: ['`tabCustomer`.`name`'],
            filters: [['Customer', 'disabled', '=', 0]],
            order_by: '`tabCustomer`.`modified` desc'
        },
        'POST'
    );

    const {
        data: paymentEntry,
        isPending: isPaymentEntryPending,
        refetch: refetchPayments
    } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Payment Entry',
            fields: [
                '`tabPayment Entry`.`name`',
                '`tabPayment Entry`.`payment_type`',
                '`tabPayment Entry`.`posting_date`',
                '`tabPayment Entry`.`mode_of_payment`',
                '`tabPayment Entry`.`paid_amount`'
            ],
            filters: [['Payment Entry Reference', 'reference_name', '=', id]],
            order_by: '`tabPayment Entry`.`modified` desc'
        },
        'POST'
    );

    const [selectedItems, setSelectedItems] = useState([]);

    const [loadingButton, setLoadingButton] = useState(false);

    const [formState, setFormState] = useState({
        due_date: moment().format('YYYY-MM-DD'),
        currency: globalDefaults?.default_currency,
        selling_price_list: '',
        set_warehouse: ''
    });
    const [addDiscountFormState, setAddDiscountFormState] = useState({
        coupon_code: '',
        additional_discount_percentage: 0,
        discount_amount: '',
        apply_discount_on: 'Grand Total'
    });

    const [customerFormState, setCustomerFormState] = useState({
        party_name: ''
    });
    const [customerFormErrState, setCustomerFormErrState] = useState({});
    const [taxFormState, setTaxFormState] = useState({
        taxes_and_charges: ''
    });

    const [customerDetails, setCustomerDetails] = useState(null);
    const [defaultTaxDetails, setDefaultTaxDetails] = useState(null);

    const [formErrState, setFormErrState] = useState({});

    const handleChange = (e) => {
        setFormIsDirty(true);
        setFormState({ ...formState, [e.target.name]: e.target.value });

        // err handlers
        if (e.target.value == '') {
            setFormErrState({ ...formErrState, [e.target.name]: true });
        } else {
            setFormErrState({ ...formErrState, [e.target.name]: false });
        }
    };

    const handleDiscntChange = (e) => {
        setFormIsDirty(true);
        setAddDiscountFormState({ ...addDiscountFormState, [e.target.name]: e.target.value });
    };

    const handleCustomerChange = (e) => {
        setFormIsDirty(true);
        setCustomerFormState({ ...customerFormState, [e.target.name]: e.target.value });

        // err handlers
        if (e.target.value == '') {
            setCustomerFormErrState({ ...customerFormErrState, [e.target.name]: true });
        } else {
            setCustomerFormErrState({ ...customerFormErrState, [e.target.name]: false });
        }
    };

    const handleDefaultTaxChange = (e) => {
        setTaxFormState({ ...taxFormState, taxes_and_charges: e.target.value });

        const data = { master_doctype: 'Sales Taxes and Charges Template', master_name: e.target.value };
        if (e?.target?.value) {
            fetch(`${process.env.REACT_APP_API_URI}/api/get-taxes-template`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify(data)
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    if (data?.message) {
                        setDefaultTaxDetails(data?.message);
                    }
                });
        }
    };

    const handleSaveSalesInvoice = () => {
        setFormIsDirty(false);
        setLoadingButton(true);
        let taxes = [];
        let itemTaxes = [];
        let items = [];

        selectedItems?.map((el) => {
            items.push({ ...el, warehouse: formState?.set_warehouse });
            if (el?.item_tax_rate && el?.item_tax_rate !== '{}') {
                itemTaxes.push(Object?.keys(JSON?.parse(el?.item_tax_rate))[0]);
            }
        });
        let uniqueItemTaxes = [...new Set(itemTaxes)];

        uniqueItemTaxes.map((el) => {
            taxes.push({
                doctype: 'Sales Taxes and Charges',
                charge_type: 'On Net Total',
                parentfield: 'taxes',
                parenttype: 'Sales Invoice',
                description: el.substring(0, el.lastIndexOf(' ')),
                account_head: el
            });
        });

        const selectedTaxTemplate = {
            doctype: 'Sales Taxes and Charges',
            charge_type: defaultTaxDetails?.[0]?.charge_type,
            parentfield: 'taxes',
            parenttype: 'Sales Invoice',
            description: defaultTaxDetails?.[0]?.description,
            account_head: defaultTaxDetails?.[0]?.account_head,
            tax_amount: defaultTaxDetails?.[0]?.tax_amount,
            rate: defaultTaxDetails?.[0]?.rate
        };

        let data = {
            ...(mappedSalesInvoice
                ? {
                      update_stock: mappedSalesInvoice?.update_stock,
                      price_list_currency: mappedSalesInvoice?.price_list_currency
                  }
                : {
                      update_stock: 1
                  }),
            doctype: 'Sales Invoice',
            __unsaved: 1,
            __islocal: 1,
            company: company,
            posting_date: moment().format('YYYY-MM-DD'),
            posting_time: moment().format('HH-mm-ss'),
            due_date: formState.due_date,

            order_type: 'Sales',
            currency: formState.currency,
            selling_price_list: formState.selling_price_list,
            ignore_pricing_rule: 1,
            apply_discount_on: addDiscountFormState?.apply_discount_on,
            group_same_items: 0,
            items: items,
            // customerDetails
            customer: customerFormState?.party_name,
            contact_email: customerDetails?.email_id,
            contact_mobile: customerDetails?.mobile_no,
            customer_name: customerDetails?.customer_name,
            customer_group: customerDetails?.customer_group,
            territory: customerDetails?.territory,
            payment_terms_template: null,
            taxes: taxFormState?.taxes_and_charges ? [selectedTaxTemplate] : taxes,
            additional_discount_percentage: parseFloat(addDiscountFormState?.additional_discount_percentage),
            discount_amount: parseFloat(addDiscountFormState?.discount_amount),
            set_warehouse: formState?.set_warehouse
        };

        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: data, action: 'Submit' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Retour vente créé avec succès' }));
                    dispatch(SetMappedSalesInvoice(null));
                    navigate(`/sales/tax-invoice/${data?.docs?.[0]?.name}`);
                }
            });
    };

    const handleCancelSalesInvoice = () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/cancel-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Sales Invoice', name: id })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Sales Invoice successfully cancelled' }));
                    navigate('/sales/tax-invoice-list');
                }
            });
    };

    const makeMappedPayment = () => {
        setDocMakingloadingButton(true);

        fetch(`${process.env.REACT_APP_API_URI}/api/document/make-mapped-payment`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ dt: 'Sales Invoice', dn: id })
        })
            .then((res) => {
                setDocMakingloadingButton(false);
                return res.json();
            })
            .then((data) => {
                if (data?.message) {
                    setMappedDoc(data?.message);
                    handleOpen();
                }
            });
    };

    const makeMappedReturnDoc = () => {
        setReturnloadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/make-mapped-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ method: 'erpnext.accounts.doctype.sales_invoice.sales_invoice.make_sales_return', source_name: id })
        })
            .then((res) => {
                setReturnloadingButton(false);
                return res.json();
            })
            .then((data) => {
                if (data?.message) {
                    setMappedDoc(data?.message);
                    handleOpen();
                }
            });
    };

    const makeMappedDeliveryNote = () => {
        setMappedDeliveryNoteLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/make-mapped-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ method: 'erpnext.accounts.doctype.sales_invoice.sales_invoice.make_delivery_note', source_name: id })
        })
            .then((res) => {
                setMappedDeliveryNoteLoadingButton(false);
                return res.json();
            })
            .then((data) => {
                if (data?.message) {
                    dispatch(SetMappedDeliveryNote(data?.message));
                    navigate('/sales/delivery-note');
                }
            });
    };

    const confirmCancelDoc = (
        <MainCard divider title={t('core:Confirmer')}>
            <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                {t('core:Cancel_Document')} <strong>{id}</strong> ?
            </Typography>
            <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item>
                            <LoadingButton
                                color="blue"
                                size="large"
                                onClick={() => handleCancelSalesInvoice()}
                                variant="contained"
                                loading={loadingButton}
                            >
                                {t('core:yes')}
                            </LoadingButton>
                        </Grid>
                        <Grid item>
                            <Button color="blue" size="large" variant="outlined" onClick={handleClose}>
                                {t('core:no')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </MainCard>
    );

    useEffect(() => {
        if (id && doc?.name) {
            setFormState({
                due_date: doc?.due_date,
                currency: doc?.currency,
                selling_price_list: doc?.selling_price_list,
                set_warehouse: doc?.set_warehouse
            });
            setAddDiscountFormState({
                coupon_code: doc?.coupon_code,
                additional_discount_percentage: doc?.additional_discount_percentage,
                discount_amount: doc?.discount_amount,
                apply_discount_on: doc?.apply_discount_on
            });
            handleDefaultTaxChange({
                target: {
                    value: doc?.taxes_and_charges
                        ? doc?.taxes_and_charges
                        : doc?.taxes?.length === 1
                        ? taxesAndCharges?.find((obj) => obj?.name?.includes(doc?.taxes?.[0]?.account_head))?.name
                        : ''
                }
            });
            setCustomerFormState({
                party_name: doc?.customer_name
            });
        }
    }, [doc]);

    useEffect(() => {
        if (mappedSalesInvoice && taxesAndCharges?.length !== 0) {
            setFormState({
                ...formState,
                currency: mappedSalesInvoice?.currency,
                selling_price_list: mappedSalesInvoice?.selling_price_list,
                set_warehouse: mappedSalesInvoice?.set_warehouse
            });
            setAddDiscountFormState({
                coupon_code: mappedSalesInvoice?.coupon_code,
                additional_discount_percentage: mappedSalesInvoice?.additional_discount_percentage,
                discount_amount: mappedSalesInvoice?.discount_amount,
                apply_discount_on: mappedSalesInvoice?.apply_discount_on
            });
            handleDefaultTaxChange({
                target: {
                    value: mappedSalesInvoice?.taxes_and_charges
                        ? mappedSalesInvoice?.taxes_and_charges
                        : mappedSalesInvoice?.taxes?.length === 1
                        ? taxesAndCharges?.find((obj) => obj?.name?.includes(mappedSalesInvoice?.taxes?.[0]?.account_head))?.name
                        : ''
                }
            });
            setCustomerFormState({
                party_name: mappedSalesInvoice?.customer_name
            });
        }
    }, [mappedSalesInvoice, taxesAndCharges]);

    useEffect(() => {
        if (customerFormState?.party_name) {
            fetch(`${process.env.REACT_APP_API_URI}/api/details?doctype=Customer&name=${customerFormState?.party_name}`, {
                method: 'GET',
                headers: header
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    if (data?.docs[0]) {
                        setCustomerDetails(data?.docs[0]);
                    }
                });
        }
    }, [customerFormState?.party_name]);

    useEffect(() => {
        const taxWithFive = taxesAndCharges.filter((tax) => tax?.name?.includes('5%'));
        if (taxWithFive?.length) {
            handleDefaultTaxChange({
                target: {
                    value: taxWithFive[0]?.name
                }
            });
        }
    }, [taxesAndCharges]);

    const disableSaveButton = () => {
        if (
            selectedItems?.length === 0 ||
            !formState?.due_date ||
            !formState?.currency ||
            !formState?.selling_price_list ||
            !formState?.set_warehouse ||
            !customerFormState.party_name
        ) {
            return true;
        }
        return false;
    };

    const handleDownloadPdf = () => {
        const options = {
            filename: `${id}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        const element = componentRef.current;

        html2pdf().from(element).set(options).save();
    };

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Box>
                <Box p={-1} display={'flex'} flexDirection={'column'}>
                    <ArrowBackIcon
                        className={classes.backLink}
                        onClick={() => {
                            navigate(-1);
                            dispatch(SetMappedSalesInvoice(null));
                        }}
                    />
                    <Box display={'flex'}>
                        {id ? `${t('products:Tax_Invoice')}: ${doc?.name}` : t('products:New_Tax_Invoice')}
                        {id && (
                            <Chip size="small" color="warning" variant="outlined" sx={{ ml: 1 }} label={statusFormat(doc?.status).value} />
                        )}
                    </Box>
                </Box>
            </Box>
            <Box>
                {doc?.name && doc?.status !== 'Cancelled' && (
                    <>
                        {(doc?.status === 'Paid' ||
                            doc?.status === 'Unpaid' ||
                            doc?.status === 'Partly Paid' ||
                            doc?.status === 'Overdue') && (
                            <LoadingButton
                                startIcon={<ReceiptIcon color={'Menu'} />}
                                variant={'contained'}
                                color="blue"
                                sx={{ ml: 1 }}
                                onClick={makeMappedDeliveryNote}
                                loading={mappedDeliveryNoteLoadingButton}
                            >
                                {`${t('core:Make_delivery_Note')} (${docCounts?.message?.count?.[1]?.count})`}
                            </LoadingButton>
                        )}
                        {doc?.status === 'Paid' && (
                            <LoadingButton
                                startIcon={<KeyboardReturnIcon color={'Menu'} />}
                                variant={'contained'}
                                color="error"
                                sx={{ ml: 1 }}
                                onClick={makeMappedReturnDoc}
                                loading={returnloadingButton}
                            >
                                {t('core:Make_Return')}
                            </LoadingButton>
                        )}
                        {(doc?.status === 'Unpaid' || doc?.status === 'Partly Paid' || doc?.status === 'Overdue') && (
                            <LoadingButton
                                startIcon={<PaidIcon color={'Menu'} />}
                                variant={'contained'}
                                color="blue"
                                sx={{ ml: 1 }}
                                onClick={makeMappedPayment}
                                loading={docMakingloadingButton}
                            >
                                {`${t('core:Make_Payment')} (${docCounts?.message?.count?.[0]?.count})`}
                            </LoadingButton>
                        )}
                        <ReactToPrint
                            trigger={() => (
                                <IconButton
                                    disabled={doc?.status !== 'Paid' && doc?.status !== 'Partly Paid'}
                                    sx={{ ml: 1 }}
                                    aria-label="delete"
                                >
                                    <PrintIcon color={doc?.status !== 'Paid' && doc?.status !== 'Partly Paid' ? 'disabled' : 'blue'} />
                                </IconButton>
                            )}
                            content={() => componentRef.current}
                            bodyClass="print-body"
                            copyStyles={true}
                            documentTitle={id}
                            pageStyle={`
                                @page {
                                    size: A4;
                                    margin: 1cm;
                                }
                                @media print {
                                    body {
                                        background-color: #f5f5f5;
                                    }
                                    .content {
                                        page-break-before: always;
                                    }
                                    .footer {
                                        position: fixed;
                                        bottom: 0;
                                        left: 0;
                                        opacity: 0.7;
                                        right: 0;
                                        background-color: #f5f5f5;
                                    }
                                }
                            `}
                            removeAfterPrint={true}
                        />
                        {/* <IconButton
                            onClick={handleDownloadPdf}
                            disabled={doc?.status !== 'Paid' && doc?.status !== 'Partly Paid'}
                            aria-label="delete"
                        >
                            <PictureAsPdfIcon color={doc?.status !== 'Paid' && doc?.status !== 'Partly Paid' ? 'disabled' : 'blue'} />
                        </IconButton> */}
                        <EmailSender
                            doctype={'Sales Invoice'}
                            name={id}
                            recipientEmail={customerDetails?.email_id}
                            disabled={doc?.status !== 'Paid' && doc?.status !== 'Partly Paid'}
                        />
                    </>
                )}
            </Box>
        </Box>
    );

    useEffect(() => {
        if (!id) {
            if (sellingPriceLists?.length) {
                setFormState({
                    ...formState,
                    selling_price_list: sellingPriceLists?.[0]?.name
                });
            }
        }
    }, [sellingPriceLists]);

    if (id && isPending) return <Loader />;
    if (
        isCurrenciesPending ||
        isWarehousesPending ||
        isSellingPriceListsPending ||
        isTaxesAndChargesPending ||
        isCustomersPending ||
        isPaymentEntryPending
    )
        return <Loader />;
    return (
        <Box>
            <MainCard title={title}>
                <Box display={'flex'} mt={-4} mb={2}>
                    <InfoIcon sx={{ mt: 0.6 }} />
                    <Typography p={1} variant="h5" color="primary">
                        {t('products:taxinvoicedesc')}
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <FormDateTime
                            required
                            type="date"
                            label={t('products:dateinvoice')}
                            name="due_date"
                            error={formErrState?.due_date}
                            value={formState.due_date}
                            handleChange={handleChange}
                            disabled={id}
                            isValidDate
                        />
                        <SelectForm
                            sx={{ mt: -2 }}
                            label={t('products:warehouse')}
                            error={formErrState?.set_warehouse}
                            value={formState.set_warehouse}
                            handleChange={handleChange}
                            data={warehouses}
                            name="set_warehouse"
                            propToRender="name"
                            required
                            disabled={id}
                        />
                        {false && (
                            <>
                                <Box mt={2} mb={2}>
                                    <Divider>
                                        <Chip label="Currency & Price List" />
                                    </Divider>
                                </Box>
                                <SelectForm
                                    sx={{ mb: 2 }}
                                    label={t('columns:currency')}
                                    error={formErrState?.currency}
                                    value={formState.currency}
                                    handleChange={handleChange}
                                    data={currencies}
                                    name="currency"
                                    propToRender="name"
                                    required
                                    disabled={id}
                                />
                                <SelectForm
                                    label={'Price List'}
                                    error={formErrState?.selling_price_list}
                                    value={formState.selling_price_list}
                                    handleChange={handleChange}
                                    data={sellingPriceLists}
                                    name="selling_price_list"
                                    propToRender="name"
                                    required
                                    disabled={id}
                                />
                            </>
                        )}
                        <Box mt={2} mb={2}>
                            <Divider>
                                <Chip label={t('core:TaxesandCharges')} />
                            </Divider>
                        </Box>
                        <SelectForm
                            sx={{ mb: 2 }}
                            label={t('core:TVA')}
                            value={taxFormState.taxes_and_charges}
                            handleChange={handleDefaultTaxChange}
                            data={taxesAndCharges}
                            name="taxes_and_charges"
                            propToRender="name"
                            disabled={id}
                        />
                        <Box mt={2} mb={2}>
                            <Divider>
                                <Chip label={t('core:AddDiscount')} />
                            </Divider>
                        </Box>
                        <InputTextField
                            sx={{ mb: 2 }}
                            isPercentage
                            type="number"
                            handleChange={handleDiscntChange}
                            value={addDiscountFormState.additional_discount_percentage}
                            label={t('core:AddDiscountt')}
                            name="additional_discount_percentage"
                            disabled={id}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <MainCard
                            title={
                                <Box display={'flex'}>
                                    <PersonIcon sx={{ mr: 1.5, ml: -1.5 }} />
                                    {t('core:Customer')}
                                </Box>
                            }
                            divider
                            border
                        >
                            <SelectForm
                                required
                                fullwidth
                                label={t('core:Customer')}
                                error={customerFormErrState?.party_name}
                                value={customerFormState?.party_name}
                                handleChange={handleCustomerChange}
                                data={customers}
                                name="party_name"
                                propToRender="name"
                                flagAddDefaultCustomer
                                refetch={refetch}
                                setFormState={setCustomerFormState}
                                disabled={id}
                            />
                            <Box mt={2} mb={2}>
                                <Divider>
                                    <Chip label={t('core:Customerdet')} />
                                </Divider>
                            </Box>
                            <Box mt={1}>
                                <Typography id="modal-modal-description" sx={{ fontSize: 14, mt: 1 }}>
                                    <strong>{t('core:Namecl')}:</strong>{' '}
                                    {customerDetails?.customer_name ? customerDetails?.customer_name : '-'}
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ fontSize: 14, mt: 1 }}>
                                    <strong>{t('core:Phonecl')}:</strong> {customerDetails?.mobile_no ? customerDetails?.mobile_no : '-'}
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ fontSize: 14, mt: 1 }}>
                                    <strong>{t('core:Addresscl')}:</strong>{' '}
                                    {customerDetails?.primary_address ? formatErpResponse(customerDetails?.primary_address) : '-'}
                                </Typography>
                            </Box>
                        </MainCard>
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <ProductTable
                        company={company}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        priceList={formState.selling_price_list}
                        currency={formState.currency}
                        addDiscountFormState={addDiscountFormState}
                        initialState={mappedSalesInvoice ? mappedSalesInvoice?.items : id ? doc?.items : []}
                        uneditable={doc?.name ? true : false}
                        warehouse={formState.set_warehouse}
                        doctype={'Sales Invoice'}
                        defaultTaxDetails={defaultTaxDetails}
                        setFormIsDirty={setFormIsDirty}
                    />
                </Box>
                {doc?.packed_items && doc?.packed_items?.length !== 0 && (
                    <Box mt={3}>
                        <BundleItemsTable packedItems={doc?.packed_items} currency={doc?.currency} />
                    </Box>
                )}
                {id && (
                    <Box mt={2}>
                        <Comments docinfo={docinfo} refetch={refetchDoc} doc={doc} />
                    </Box>
                )}
                <Box display={'flex'} justifyContent={'right'} mt={3}>
                    <LoadingButton
                        disabled={doc?.name ? false : disableSaveButton()}
                        loading={loadingButton}
                        variant="contained"
                        size="large"
                        color={doc?.name ? 'error' : 'blue'}
                        onClick={doc?.name ? handleOpen : handleSaveSalesInvoice}
                    >
                        {doc?.name ? t('common:cancel') : t('crm:save')}
                    </LoadingButton>
                </Box>
            </MainCard>
            {doc?.name && (
                <Box display={'none'}>
                    <PrintInvoiceTemplate
                        ref={componentRef}
                        doc={doc}
                        docinfo={docinfo}
                        selectedItems={selectedItems}
                        isInvoices
                        customerDetails={customerDetails}
                        defaultTaxDetails={defaultTaxDetails}
                        paymentEntry={paymentEntry}
                    />
                </Box>
            )}
            <Modal onClose={handleClose} open={open}>
                <Box sx={modalStyle}>
                    {mappedDoc?.doctype === 'Payment Entry' ? (
                        <SalesInvoicePayment
                            refetchDoc={refetchDoc}
                            refetchCount={refetchCount}
                            refetchPayments={refetchPayments}
                            mappedDoc={mappedDoc}
                            handleClose={handleClose}
                            company={company}
                        />
                    ) : mappedDoc?.doctype === 'Sales Invoice' ? (
                        <SalesInvoiceReturn refetchDoc={refetchDoc} mappedDoc={mappedDoc} handleClose={handleClose} company={company} />
                    ) : (
                        confirmCancelDoc
                    )}
                </Box>
            </Modal>
            <NavigationModal open={modalOpen} onClose={handleCloseBlocker} />
        </Box>
    );
};

export default SalesInvoiceForm;
