import { LoadingButton } from '@mui/lab';
import { Button, Grid, IconButton, Modal, Typography } from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Box } from '@mui/system';
import useFetch from 'hooks/useFetch';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import DataRender from 'ui-component/tables/DataRender';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@mui/material/styles';
import { modalStyle } from 'views/utilities/ModalStyle';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import { formatErpResponse } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import DetailModal from './component/DetailModal';

const ItemGroupList = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [selectionModel, setSelectionModel] = useState([]);
    const [detailModalOpen, setDetailModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const [open, setOpen] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const itemGroupsReqData = {
        doctype: 'Item Group',
        fields: [
            '`tabItem Group`.`creation`',
            '`tabItem Group`.`item_group_code`',
            '`tabItem Group`.`name`',
            '`tabItem Group`.`is_group`',
            '`tabItem Group`.`parent_item_group`'
        ],
        start: 0,
        page_length: 500
    };

    const { data, isPending, refetch } = useFetch(`/api/get-list`, itemGroupsReqData, 'POST');

    const handleEdit = (row) => {
        navigate(`/pim/category-view/${row}`);
    };
    const handleShowDetails = (row) => {
        navigate(`/pim/products-category-list-view/${row}`);
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleDelete = (row) => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Item Group', name: row[0] })
        })
            .then((res) => res.json())
            .then((data) => {
                setLoadingButton(false);
                if (data?.success === false) {
                    const errorMessage = formatErpResponse(data?.message);
                    if (errorMessage.includes('Root Item Group cannot be deleted')) {
                        dispatch(
                            SetNotification({
                                code: 'warning',
                                message: t('common:rootItemGroupCannotBeDeleted')
                            })
                        );
                    }
                    if (errorMessage.includes('Cannot delete or cancel')) {
                        dispatch(
                            SetNotification({
                                code: 'warning',
                                message: t('common:item_group_is_linked', { item_group: row[0] })
                            })
                        );
                    } else {
                        dispatch(
                            SetNotification({
                                code: 'warning',
                                message: errorMessage
                            })
                        );
                    }
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('common:deleteCatSuccess') }));
                    refetch({});
                    handleClose();
                }
            })
            .catch((error) => {
                setLoadingButton(false);
                dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
            });
    };

    const deleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('common:deletePermanently')} <strong>{selectionModel}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button variant="outlined" color="blue" size="large" onClick={handleClose}>
                                    {t('common:non')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleDelete(selectionModel)}
                                    variant="contained"
                                    loading={loadingButton}
                                >
                                    {t('common:oui')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Grid container spacing={1}>
                {false && (
                    <>
                        <Grid item>
                            {selectionModel.length === 1 && (
                                <Button
                                    startIcon={<DeleteIcon color="error" />}
                                    variant="outlined"
                                    color="error"
                                    onClick={handleOpen}
                                    sx={{ marginTop: -3 }}
                                >
                                    {t('common:delete')}
                                </Button>
                            )}
                        </Grid>
                        <Grid item>
                            {selectionModel.length === 1 && (
                                <Button
                                    startIcon={<EditIcon />}
                                    variant="outlined"
                                    color="blue"
                                    onClick={() => handleEdit(selectionModel)}
                                    sx={{ marginTop: -3 }}
                                >
                                    {t('common:edit')}
                                </Button>
                            )}
                        </Grid>
                    </>
                )}
                <Grid item xs={6}>
                    {t('products:categoriesList')}
                    <IconButton
                        aria-haspopup="true"
                        onClick={() => navigate('/pim/products-category-tree-view')}
                        color="inherit"
                        aria-label="cart"
                        variant="rounded"
                        size="small"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: 'transparent !important',
                            color: theme.palette.primary.dark,
                            borderRadius: 50,
                            height: 20,
                            ml: 2,
                            mt: -0.5
                        }}
                    >
                        <AccountTreeIcon
                            sx={{
                                height: 20
                            }}
                            color="blue"
                        />
                    </IconButton>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end">
                    <Button
                        startIcon={<AddCircleIcon />}
                        variant="outlined"
                        color="blue"
                        onClick={() => navigate('/pim/products-new-category')}
                    >
                        {t('products:addCategory')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );

    const handleOpenDetailModal = (row) => {
        setSelectedRow(row);
        setDetailModalOpen(true);
    };

    const handleCloseDetailModal = () => {
        setDetailModalOpen(false);
        setSelectedRow(null);
    };
    const Produit = 'Produit';
    return (
        <div>
            <DataRender
                Produit
                refetch={refetch}
                title={title}
                data={data}
                loading={isPending}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleShowDetails={handleShowDetails}
                showDetails={true}
                handleOpenDetailModal={handleOpenDetailModal}
                Visualize={true}
            />
            <Modal onClose={handleClose} open={open}>
                {deleteConfirm}
            </Modal>
            <DetailModal open={detailModalOpen} onClose={handleCloseDetailModal} row={selectedRow} />
        </div>
    );
};

export default ItemGroupList;
