import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box } from '@mui/system';
import InputTextField from 'ui-component/form/InputTextField';
import SelectForm from 'ui-component/form/SelectForm';
import InputCheckboxField from 'ui-component/form/InputCheckboxField';
import { LoadingButton } from '@mui/lab';
import useGetAxios from 'hooks/useGetAxios';
import { useEffect } from 'react';
import Loader from 'ui-component/Loader';
import useFetch from 'hooks/useFetch';
import { formatErpResponse } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { SetNotification } from 'store/services/api';
import { useTranslation } from 'react-i18next';
import FormDateTime from 'ui-component/dateTime/FormDateTime';

const StockSettings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loadingButton, setLoadingButton] = React.useState(false);

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const {
        data: doc,
        isPending,
        refetch: refetchDoc,
        docinfo
    } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Stock%20Settings&name=Stock%20Settings`);

    const [state, setState] = React.useState({
        item_naming_by: '',
        item_group: '',
        stock_uom: '',
        default_warehouse: '',
        valuation_method: '',
        use_naming_series: 0,
        naming_series_prefix: 'BATCH-',
        over_delivery_receipt_allowance: 0,
        role_allowed_to_over_deliver_receive: '',
        mr_qty_allowance: 0,
        auto_insert_price_list_rate_if_missing: 0,
        update_existing_price_list_rate: 0,
        allow_negative_stock: 0,
        show_barcode_field: 0,
        clean_description_html: 0,
        action_if_quality_inspection_is_not_submitted: '',
        action_if_quality_inspection_is_rejected: '',
        auto_indent: 0,
        automatically_set_serial_nos_based_on_fifo: 0,
        set_qty_in_transactions_based_on_serial_no_input: 0,
        disable_serial_no_and_batch_selector: 0,
        reorder_email_notify: 0,
        allow_from_dn: 0,
        allow_from_pr: 0,
        stock_frozen_upto: 0,
        stock_frozen_upto_days: 0,
        role_allowed_to_create_edit_back_dated_transactions: '',
        stock_auth_role: '',
        sample_retention_warehouse: ''
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const handleChangeCheckbox = (event, value) => {
        setState({ ...state, [event.target.name]: value ? 1 : 0 });
    };

    const warehouseReqData = {
        doctype: 'Warehouse',
        fields: ['`tabWarehouse`.`name`'],
        order_by: '`tabWarehouse`.`modified` desc',
        start: 0,
        page_length: 50
    };
    const itemGroupReqData = {
        doctype: 'Item Group',
        fields: ['`tabItem Group`.`name`'],
        order_by: '`tabItem Group`.`modified` desc',
        start: 0,
        page_length: 50
    };
    const UomReqData = {
        doctype: 'UOM',
        fields: ['`tabUOM`.`name`']
    };
    const { data: UOM, isPending: isPendingUOMs } = useFetch(`/api/get-list`, UomReqData, 'POST');
    const roleReqData = {
        doctype: 'Role',
        fields: ['`tabRole`.`name`'],
        order_by: '`tabRole`.`modified` desc',
        start: 0,
        page_length: 200
    };

    const { data: warehouses, isPending: isPendingWarehouse, refetch } = useFetch(`/api/get-list`, warehouseReqData, 'POST');
    const {
        data: itemGroups,
        isPending: isPendingItemGroups,
        refetch: refetchItemGroup
    } = useFetch(`/api/get-list`, itemGroupReqData, 'POST');
    //const { data: uom, isPending: isPendingUom, refetch: refetchUom } = useFetch(`/api/get-list`, UOMReqData, 'POST');
    const { data: roles, isPending: isPendingRoles, refetch: refetchRoles } = useFetch(`/api/get-list`, roleReqData, 'POST');
    useEffect(() => {
        setState({
            item_naming_by: doc.item_naming_by,
            item_group: doc.item_group,
            stock_uom: doc.stock_uom,
            default_warehouse: doc.default_warehouse,
            over_delivery_receipt_allowance: doc.over_delivery_receipt_allowance,
            role_allowed_to_over_deliver_receive: doc.role_allowed_to_over_deliver_receive,
            mr_qty_allowance: doc.mr_qty_allowance,
            valuation_method: doc.valuation_method,
            use_naming_series: doc.use_naming_series,
            naming_series_prefix: doc.naming_series_prefix,
            auto_insert_price_list_rate_if_missing: doc.auto_insert_price_list_rate_if_missing,
            update_existing_price_list_rate: doc.update_existing_price_list_rate,
            allow_negative_stock: doc.allow_negative_stock,
            show_barcode_field: doc.show_barcode_field,
            clean_description_html: doc.clean_description_html,
            action_if_quality_inspection_is_not_submitted: doc.action_if_quality_inspection_is_not_submitted,
            action_if_quality_inspection_is_rejected: doc.action_if_quality_inspection_is_rejected,
            automatically_set_serial_nos_based_on_fifo: doc.automatically_set_serial_nos_based_on_fifo,
            set_qty_in_transactions_based_on_serial_no_input: doc.set_qty_in_transactions_based_on_serial_no_input,
            disable_serial_no_and_batch_selector: doc.disable_serial_no_and_batch_selector,
            auto_indent: doc.auto_indent,
            reorder_email_notify: doc.reorder_email_notify,
            allow_from_dn: doc.allow_from_dn,
            allow_from_pr: doc.allow_from_pr,
            stock_frozen_upto: doc.stock_frozen_upto,
            stock_frozen_upto_days: doc.stock_frozen_upto_days,
            role_allowed_to_create_edit_back_dated_transactions: doc.role_allowed_to_create_edit_back_dated_transactions,
            stock_auth_role: doc.stock_auth_role,
            sample_retention_warehouse: doc.sample_retention_warehouse
        });
    }, [doc]);

    const handleSubmit = () => {
        const data = {
            ...doc,
            doctype: 'Stock Settings',
            item_naming_by: state.item_naming_by,
            item_group: state.item_group,
            stock_uom: state.stock_uom,
            default_warehouse: state.default_warehouse,
            over_delivery_receipt_allowance: state.over_delivery_receipt_allowance,
            role_allowed_to_over_deliver_receive: state.role_allowed_to_over_deliver_receive,
            mr_qty_allowance: state.mr_qty_allowance,
            valuation_method: state.valuation_method,
            use_naming_series: state.use_naming_series,
            naming_series_prefix: state.naming_series_prefix,
            auto_insert_price_list_rate_if_missing: state.auto_insert_price_list_rate_if_missing,
            update_existing_price_list_rate: state.update_existing_price_list_rate,
            allow_negative_stock: state.allow_negative_stock,
            show_barcode_field: state.show_barcode_field,
            clean_description_html: state.clean_description_html,
            action_if_quality_inspection_is_not_submitted: state.action_if_quality_inspection_is_not_submitted,
            action_if_quality_inspection_is_rejected: state.action_if_quality_inspection_is_rejected,
            automatically_set_serial_nos_based_on_fifo: state.automatically_set_serial_nos_based_on_fifo,
            set_qty_in_transactions_based_on_serial_no_input: state.set_qty_in_transactions_based_on_serial_no_input,
            disable_serial_no_and_batch_selector: state.disable_serial_no_and_batch_selector,
            auto_indent: state.auto_indent,
            reorder_email_notify: state.reorder_email_notify,
            allow_from_dn: state.allow_from_dn,
            allow_from_pr: state.allow_from_pr,
            stock_frozen_upto: state.stock_frozen_upto,
            stock_frozen_upto_days: parseInt(state.stock_frozen_upto_days),
            role_allowed_to_create_edit_back_dated_transactions: state.role_allowed_to_create_edit_back_dated_transactions,
            stock_auth_role: state.stock_auth_role,
            sample_retention_warehouse: state.sample_retention_warehouse
        };
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: data, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'error', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Stock Settings successfully updated' }));
                    refetchDoc({});
                }
            });
    };
    const actionInspectionOptions = ['Warn', 'Stop'];

    if (isPending && (isPendingItemGroups || isPendingUOMs || isPendingRoles)) return <Loader />;
    return (
        <>
            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-27')}
                    </Box>
                }
                sx={{ height: '20%' }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <SelectForm
                            fullwidth
                            label={t('settings:text-28')}
                            data={['Item Code', 'Naming Series']}
                            name="item_naming_by"
                            value={state.item_naming_by}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                        />
                        <SelectForm
                            fullwidth
                            label={t('settings:text-29')}
                            data={itemGroups ?? itemGroups}
                            name="item_group"
                            value={state.item_group}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            propToRender={'name'}
                        />
                        <SelectForm
                            fullwidth
                            label={t('settings:text-30')}
                            data={UOM ?? []}
                            name="stock_uom"
                            value={state.stock_uom}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            propToRender={'name'}
                        />
                        <SelectForm
                            fullwidth
                            label={t('settings:text-31')}
                            name="default_warehouse"
                            value={state.default_warehouse}
                            data={warehouses ?? warehouses}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            propToRender={'name'}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <SelectForm
                            fullwidth
                            label={t('settings:text-32')}
                            name="valuation_method"
                            value={state.valuation_method}
                            data={['FIFO', 'Moving Average']}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                        />

                        <SelectForm
                            fullwidth
                            label={t('settings:text-54')}
                            name="sample_retention_warehouse"
                            value={state.sample_retention_warehouse}
                            data={warehouses ?? warehouses}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            propToRender={'name'}
                        />

                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-33')}
                            name="use_naming_series"
                            value={state.use_naming_series === 1 ? true : false}
                            sx={{ mb: 2 }}
                            handleChange={handleChangeCheckbox}
                        />
                        {state.use_naming_series != 0 && (
                            <InputTextField
                                fullwidth
                                type="text"
                                label={t('settings:text-34')}
                                name="naming_series_prefix"
                                value={state.naming_series_prefix}
                                sx={{ mb: 2 }}
                                handleChange={handleChange}
                            />
                        )}
                    </div>
                </div>
            </MainCard>

            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-35')}
                    </Box>
                }
                sx={{ height: '21%', mt: 2 }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <InputTextField
                            fullwidth
                            label={t('settings:text-55')}
                            name="over_delivery_receipt_allowance"
                            value={state.over_delivery_receipt_allowance}
                            handleChange={handleChange}
                            helper={t('settings:text-56')}
                        />
                        <SelectForm
                            fullwidth
                            label={t('settings:text-57')}
                            data={roles ?? []}
                            name="role_allowed_to_over_deliver_receive"
                            value={state.role_allowed_to_over_deliver_receive}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            propToRender={'name'}
                            helper={t('settings:text-58')}
                        />
                        <InputTextField
                            fullwidth
                            label={t('settings:text-59')}
                            name="mr_qty_allowance"
                            value={state.mr_qty_allowance}
                            handleChange={handleChange}
                            helper={t('settings:text-60')}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-36')}
                            name="auto_insert_price_list_rate_if_missing"
                            value={state.auto_insert_price_list_rate_if_missing === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-37')}
                            name="update_existing_price_list_rate"
                            value={state.update_existing_price_list_rate === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-38')}
                            name="allow_negative_stock"
                            value={state?.allow_negative_stock === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-39')}
                            name="show_barcode_field"
                            value={state?.show_barcode_field === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-40')}
                            name="clean_description_html"
                            value={state?.clean_description_html === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                    </div>
                </div>
            </MainCard>

            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-61')}
                    </Box>
                }
                sx={{ height: '12%', mt: 2 }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <SelectForm
                            fullwidth
                            label={t('settings:text-62')}
                            name="action_if_quality_inspection_is_not_submitted"
                            value={state.action_if_quality_inspection_is_not_submitted}
                            sx={{ mb: 2 }}
                            data={actionInspectionOptions}
                            handleChange={handleChange}
                        />
                        <SelectForm
                            fullwidth
                            label={t('settings:text-63')}
                            name="action_if_quality_inspection_is_rejected"
                            value={state.action_if_quality_inspection_is_rejected}
                            sx={{ mb: 2 }}
                            data={actionInspectionOptions}
                            handleChange={handleChange}
                        />
                    </div>
                    <div style={{ width: '100%' }}></div>
                </div>
            </MainCard>

            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-41')}
                    </Box>
                }
                sx={{ height: '10%', mt: 2 }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-42')}
                            name="automatically_set_serial_nos_based_on_fifo"
                            value={state?.automatically_set_serial_nos_based_on_fifo === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-43')}
                            name="set_qty_in_transactions_based_on_serial_no_input"
                            value={state?.set_qty_in_transactions_based_on_serial_no_input === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-44')}
                            name="disable_serial_no_and_batch_selector"
                            value={state?.disable_serial_no_and_batch_selector === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                    </div>
                </div>
            </MainCard>

            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-45')}
                    </Box>
                }
                sx={{ height: '10%', mt: 2 }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-46')}
                            name="auto_indent"
                            value={state?.auto_indent === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-64')}
                            name="reorder_email_notify"
                            value={state?.reorder_email_notify === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                    </div>
                </div>
            </MainCard>

            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-47')}
                    </Box>
                }
                sx={{ height: '10%', mt: 2 }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-48')}
                            name="allow_from_dn"
                            value={state?.allow_from_dn === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('settings:text-49')}
                            name="allow_from_pr"
                            value={state?.allow_from_pr === 1 ? true : false}
                            handleChange={handleChangeCheckbox}
                            sx={{ mb: 2 }}
                        />
                    </div>
                </div>
            </MainCard>

            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} /> {t('settings:text-65')}
                    </Box>
                }
                sx={{ height: '17%', mt: 2 }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <FormDateTime
                            fullwidth
                            type="date"
                            label={t('settings:text-66')}
                            name="stock_frozen_upto"
                            value={state.stock_frozen_upto}
                            handleChange={handleChange}
                            sx={{ mb: 2 }}
                            helper={t('settings:text-67')}
                        />
                        <InputTextField
                            fullwidth
                            label={t('settings:text-68')}
                            name="stock_frozen_upto_days"
                            value={state.stock_frozen_upto_days}
                            handleChange={handleChange}
                            helper={t('settings:text-69')}
                            sx={{ mb: 2 }}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <SelectForm
                            fullwidth
                            label={t('settings:text-70')}
                            data={roles ?? []}
                            name="role_allowed_to_create_edit_back_dated_transactions"
                            value={state.role_allowed_to_create_edit_back_dated_transactions}
                            sx={{ mb: 2 }}
                            handleChange={handleChange}
                            propToRender={'name'}
                            helper={t('settings:text-71')}
                        />
                        {state.stock_frozen_upto && (
                            <SelectForm
                                fullwidth
                                label={t('settings:text-72')}
                                data={roles ?? []}
                                name="stock_auth_role"
                                value={state.stock_auth_role}
                                sx={{ mb: 2 }}
                                handleChange={handleChange}
                                propToRender={'name'}
                                helper={t('settings:text-73')}
                            />
                        )}
                    </div>
                </div>
            </MainCard>

            <Box display={'flex'} justifyContent={'right'} mt={3}>
                <LoadingButton variant="contained" size="large" color="blue" onClick={handleSubmit} loading={loadingButton}>
                    {t('settings:text-50')}
                </LoadingButton>
            </Box>
        </>
    );
};

export default StockSettings;
