{/* eslint-disable */}
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import MainCard from 'ui-component/cards/MainCard';
import { frLocal } from 'utils/dataGridFRLocal';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Chip, Grid, Modal, Typography } from '@mui/material';
import { modalStyle } from 'views/utilities/ModalStyle';
import { useTheme } from '@mui/material/styles';
import { formatColumnsHeaders, formatData, formatColumnsString } from 'utils/formatColumnsHeaders';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'views/utilities/Style';
import { CustomNoRowsOverlay } from 'ui-component/noRowsOverlay/CustomNoRowsOverlay';
import { CircularProgressLoader } from 'ui-component/CircularProgressLoader';
import Checkbox from '@mui/material/Checkbox';

const DataRender = ({
    data,
    loading,
    title,
    selectionModel,
    setSelectionModel,
    handleEdit,
    handleDelete,
    hasCheckBoxes,
    noActions,
    isPrintable,
    componentRef,
    handlePreview,
    rowId,
    showDetails,
    handleShowDetails,
    onRowDoubleClick,
    ischild,
    notshow,
    isViewAction,
    isSales,
    refetch,
    isDisable,
    tag,
    isUser,
    handleGetUserByEmail,
    setUser,
    handleDataToDelete,
    fetchLoading,
    handleCancelModal,
    hasImage,
    getImgUrl,
    columnsDef,
    Produit,
    Details,
    customFilter,
    initialState = {},
    warehouseType,
    handleOpenDetailModal,
    Visualize = false,
    hideDelete = false
}) => {
    const theme = useTheme();
    const classes = useStyles();

    const lookup4Color = {
        Draft: theme.palette.error.main,
        Return: theme.palette.error.main,
        Paid: theme.palette.success.main,
        'Partly Paid': theme.palette.warning.main,
        Unpaid: theme.palette.warning.main,
        Overdue: theme.palette.error.main,
        'Credit Note Issued': theme.palette.info.main,
        Submitted: theme.palette.success.main,
        'Unpaid and Discounted': theme.palette.warning.main,
        'Partly Paid and Discounted': theme.palette.warning.main,
        'Overdue and Discounted': theme.palette.error.main,
        'Internal Transfer': theme.palette.info.main,
        'On Hold': theme.palette.warning.main,
        'To Deliver and Bill': theme.palette.warning.main,
        'To Bill': theme.palette.warning.main,
        'To Deliver': theme.palette.warning.main,
        Completed: theme.palette.success.main,
        Cancelled: theme.palette.error.main,
        Closed: theme.palette.warning.main,
        Consolidated: theme.palette.success.main,
        'Return Issued': theme.palette.error.main,
        Open: theme.palette.warning.main,
        Replied: theme.palette.info.main,
        Expired: theme.palette.error.main,
        Lost: theme.palette.error.main,
        Ordered: theme.palette.success.main,
        'Partially Ordered': theme.palette.warning.main
    };

    function getColor(name) {
        for (let key in lookup4Color) {
            if (name?.toLowerCase() === key?.toLowerCase()) {
                return lookup4Color[key];
            }
        }
        return theme.palette.info.main;
    }

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0
    });

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const [idToDelete, setIdToDelete] = useState(null);
    const { t, i18n } = useTranslation();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setIdToDelete(null);
        isUser && setUser('');
        handleCancelModal && handleCancelModal();
    };

    const handleTheDelete = React.useCallback(
        (id) => () => {
            setIdToDelete(id);
            handleOpen();
            isUser && handleGetUserByEmail(id);
            handleDataToDelete && handleDataToDelete(id);
        },
        []
    );

    const deleteActionCaptured = async () => {
        handleDelete && (await handleDelete([idToDelete]));
        refetch && refetch({});
        handleClose();
    };

    const handleDetails = React.useCallback(
        (id) => () => {
            handlePreview ? handlePreview(id) : handleEdit(id);
        },
        []
    );

    const handleTheEdit = React.useCallback(
        (id) => () => {
            handleEdit && handleEdit(id);
        },
        []
    );

    const deleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={isSales ? t('core:Confirmer') : t('core:Confirmer')}>
                {fetchLoading ? (
                    <CircularProgressLoader />
                ) : (
                    <>
                        <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                            {isSales ? t('core:suppDef') : isDisable ? t('core:disable') : t('core:suppDef')} <strong>{idToDelete}</strong>{' '}
                            ?
                        </Typography>
                        <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                            <Box>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Button color="blue" size="large" onClick={() => deleteActionCaptured()} variant="contained">
                                            {isSales ? t('core:yes') : t('users:yes')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined" color="blue" size="large" onClick={handleClose}>
                                            {isSales ? t('core:no') : t('users:no')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                )}
            </MainCard>
        </Box>
    );

    const actions = {
        field: 'actions',
        type: 'actions',
        width: 180,
        align: 'right',
        headerAlign: 'right',
        getActions: (params) => {
            const actionItems = [];
            if (Visualize) {
                actionItems.push(
                    <GridActionsCellItem
                        sx={{ bgcolor: theme.palette.secondary.main }}
                        icon={isViewAction ? <EditIcon color="primary" /> : <VisibilityIcon color="primary" />}
                        label="Visualize"
                        onClick={() => handleOpenDetailModal(params.row)}
                    />
                );
            }
            actionItems.push(
                <GridActionsCellItem
                    sx={{ bgcolor: theme.palette.secondary.main }}
                    icon={isViewAction ? <VisibilityIcon color="primary" /> : <EditIcon color="primary" />}
                    label="Edit"
                    onClick={handleTheEdit(params.id)}
                />
            );
            if (!hideDelete) {
                actionItems.push(
                    <GridActionsCellItem
                        sx={{ bgcolor: theme.palette.secondary.main }}
                        icon={<DeleteIcon color="error" />}
                        label="Delete"
                        onClick={handleTheDelete(params.id)}
                    />
                );
            }
            return actionItems;
        }
    };
    const showdetails = {
        field: 'Actions',
        type: 'actions',
        width: 140,
        align: 'right',
        headerAlign: 'right',
        getActions: (params) => [
            <Button
                key={params.id}
                startIcon={<VisibilityIcon />}
                variant="outlined"
                color="blue"
                onClick={() => handleShowDetails(params.id)}
                /*  sx={{ marginTop: -3 }} */
            >
                {Produit ? 'Produit' : Details}
            </Button>
        ]
    };
    const getColumns = (data) => {
        let columns = [];

        if (hasImage) {
            columns.push({
                field: 'itemImage',
                headerName: 'Image',
                width: 100,
                renderCell: (params) => {
                    const imageUrl = getImgUrl(params.row.name);
                    return (
                        <Box
                            sx={{
                                width: 50,
                                height: 50,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <img
                                src={imageUrl}
                                alt={params.row.name}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    borderRadius: '4px'
                                }}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = 'https://upload.wikimedia.org/wikipedia/fr/4/45/CS_Sfaxien.gif';
                                }}
                            />
                        </Box>
                    );
                }
            });
        }

        if (data && Array.isArray(data) && data?.length > 0) {
            Object.keys(data[0]).map((column) => {
                var showthiscol = true;
                if (notshow && Array.isArray(notshow)) {
                    const index = notshow.findIndex((el) => el == column);
                    if (index != -1) {
                        showthiscol = false;
                    }
                }
                if (showthiscol) {
                    let columnDef = {
                        field: column,
                        headerName: isSales 
                            ? formatColumnsString(column) 
                            : t(formatColumnsHeaders(column, tag, warehouseType)),
                        flex: 1
                    };

                    if (column === 'buying' || column === 'selling') {
                        columnDef = {
                            ...columnDef,
                            renderCell: (params) => (
                                <Checkbox
                                    checked={params.value === 1}
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            color: 'rgba(128, 128, 128, 0.3)'
                                        },
                                        '&.Mui-checked': {
                                            color: 'rgba(128, 128, 128, 0.3)'
                                        },
                                        '&.Mui-disabled': {
                                            color: 'rgba(128, 128, 128, 0.3)'
                                        }
                                    }}
                                />
                            )
                        };
                    }
                    if (column === 'status') {
                        columnDef = {
                            ...columnDef,
                            align: 'center',
                            headerAlign: 'center',
                            renderCell: (params) => (
                                <Chip size="small" label={params.value} sx={{ color: '#fff', backgroundColor: getColor(params.value) }} />
                            )
                        };
                    }
                    if (column === 'disable') {
                        columnDef = {
                            ...columnDef,
                            renderCell: (params) => (
                                <Checkbox
                                    checked={params.value === 1}
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            color: 'rgba(128, 128, 128, 0.3)'
                                        },
                                        '&.Mui-checked': {
                                            color: 'rgba(128, 128, 128, 0.3)'
                                        },
                                        '&.Mui-disabled': {
                                            color: 'rgba(128, 128, 128, 0.3)'
                                        }
                                    }}
                                />
                            )
                        };
                    }
                    columns.push(columnDef);
                }
            });

            !noActions && columns.push(actions);
            showDetails && columns.push(showdetails);
            return columns;
        }
        return [];
    };
    const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
    useEffect(() => {
        if (!loading && data && data?.length > 0) {
            setRows(formatData(data));
            setColumns(getColumns(data));
        }
    }, [data, getLanguage()]);

    return (
        <Box>
            <MainCard title={title}>
                {customFilter && <Box sx={{ mb: 2 }}>{customFilter}</Box>}
                <Box sx={{ height: ischild ? 500 : 823 }}>
                <DataGrid
                        initialState={initialState}
                        checkboxSelection={hasCheckBoxes}
                        onRowSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel && setSelectionModel(newSelectionModel);
                        }}
                        rowSelectionModel={selectionModel}
                        experimentalFeatures={{ newEditingApi: true }}
                        loading={loading}
                        rows={rows}
                        columns={columns}
                        getRowId={(row) => row.name}
                        localeText={frLocal}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        slots={{ toolbar: GridToolbar, noRowsOverlay: CustomNoRowsOverlay }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                                printOptions: { disableToolbarButton: true }
                            }
                        }}
                        //disableColumnSelector
                        disableDensitySelector
                        disableColumnMenu
                        density="standard"
                    />
                </Box>
            </MainCard>
            <Modal onClose={handleClose} open={open}>
                {deleteConfirm}
            </Modal>
        </Box>
    );
};
export default DataRender;
