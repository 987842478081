/* eslint-disable no-undef */
import { useStyles } from '../../utilities/Style';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { SetNotification } from '../../../store/services/api';
import { modalStyle } from '../../utilities/ModalStyle';
import { formatErpResponse } from '../../../utils/utils';
import { Box } from '@mui/system';
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MainCard from '../../../ui-component/cards/MainCard';
import BarcodesForm from '../productManagement/components/components/BarcodesForm';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import FormDateTime from '../../../ui-component/dateTime/FormDateTime';
import { LoadingButton } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SelectForm from '../../../ui-component/form/SelectForm';
import { Check } from '@mui/icons-material';
import { Field } from 'formik';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTheme } from '@mui/material/styles';

const ProductFinancierAdd = () => {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const [itemCode, setItemCode] = useState('');
    const [enabled, setEnabled] = useState(false);
    const [libelle, setLibelle] = useState('');
    const [prix, setPrix] = useState('');
    const [commentaire, setCommentaire] = useState('');
    const [division, setDivision] = useState('');
    const [typeProduct, setTypeProduct] = useState('');
    const [rattachement, setRattachement] = useState('');
    const [typeArticle, setTypeArticle] = useState('');
    const [typeUtilisation, setTypeUtilisation] = useState('');
    const [typeDemandeCredit, setTypeDemandeCredit] = useState('');
    const [familleComptable, setFamilleComptable] = useState('');
    const [familleComptableList, setFamilleComptableList] = useState([]);
    const [journalComptableList, setJournalComptableList] = useState([]);
    const [typefamilleComptable, setTypeFamilleComptable] = useState('Asset');
    const [journalComptable, setJournalComptable] = useState('');
    const [compteComptable, setCompteComptable] = useState('');
    const [attribution, setAttribution] = useState('');
    const [modePaiementAssocie, setModePaiementAssocie] = useState('');
    const [loadingButton, setLoadingButton] = useState(false);
    const [barcodesState, setBarcodesState] = useState([]);
    const [dateDebut, setDateDebut] = useState('');
    const [dateFin, setDateFin] = useState('');
    const [loadingAccount, setLoadingAccount] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [errors, setErrors] = useState({});
    const [attemptedSubmit, setAttemptedSubmit] = useState(false);
    const theme = useTheme();

    const [state, setState] = useState({
        modePaiementAssocie: []
    });

    const barcodesettingsReqData = {
        doctype: 'Barcode Settings',
        fields: [
            '`tabBarcode Settings`.`name`',
            '`tabBarcode Settings`.`barcode_type`',
            '`tabBarcode Settings`.`suffix`',
            '`tabBarcode Settings`.`automatic`'
        ]
    };
    const { data: barcodesettings, isPending: isPendingBarcodeSettings } = useFetch(`/api/get-list`, barcodesettingsReqData, 'POST');

    let globalAutomatic;
    let barcodeTypeOptions;

    if (barcodesettings) {
        barcodesettings.forEach((setting) => {
            const name = setting.name;
            const barcode_type = setting.barcode_type;
            const suffix = setting.suffix;
            const automatic = setting.automatic;
            globalAutomatic = automatic;
            barcodeTypeOptions = barcode_type;
        });
    }

    const [checkboxState, setCheckboxState] = useState({
        utilisation: false,
        fidelite: false,
        indices: false,
        quantites: false,
        tpe: false,
        remisable: false,
        imprimeFiscale: false,
        preEnregistre: false
    });
    const handleCheckboxChange = (name) => (event) => {
        setCheckboxState({ ...checkboxState, [name]: event.target.checked });
    };

    const handleInputChange = (name, value) => {
        switch (name) {
            case 'itemCode':
                setItemCode(value);
                break;
            case 'libelle':
                setLibelle(value);
                break;
            case 'division':
                setDivision(value);
                break;
            case 'typeProduct':
                setTypeProduct(value);
                break;
            case 'rattachement':
                setRattachement(value);
                break;
            case 'typeArticle':
                setTypeArticle(value);
                break;
            case 'typeUtilisation':
                setTypeUtilisation(value);
                break;
            case 'typeDemandeCredit':
                setTypeDemandeCredit(value);
                break;
            case 'dateDebut':
                setDateDebut(value);
                break;
            case 'dateFin':
                setDateFin(value);
                break;
        }

        const error = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error
        }));

        checkFormValidity();
    };

    useEffect(() => {
        const isValid =
            required.every((field) => {
                const value = eval(field);
                return value && value.trim() !== '';
            }) &&
            barcodesState.length > 0 &&
            barcodesState.every((barcode) => barcode.barcode.trim() !== '');
        setIsFormValid(isValid);
    }, [
        itemCode,
        libelle,
        division,
        typeProduct,
        rattachement,
        typeArticle,
        typeUtilisation,
        typeDemandeCredit,
        dateDebut,
        dateFin,
        barcodesState
    ]);

    const required = [
        'itemCode',
        'libelle',
        'division',
        'typeProduct',
        'rattachement',
        'typeArticle',
        'typeUtilisation',
        'typeDemandeCredit',
        'dateDebut',
        'dateFin'
    ];

    const validateField = (name, value) => {
        if (required.includes(name) && (!value || value.trim() === '')) {
            return t('products:isfieldrequired');
        }
        return '';
    };

    const checkFormValidity = () => {
        const newErrors = {};
        required.forEach((field) => {
            const value = eval(field);
            newErrors[field] = validateField(field, value);
        });
        setErrors(newErrors);

        const isValid =
            Object.values(newErrors).every((error) => !error) &&
            barcodesState.length > 0 &&
            barcodesState.every((barcode) => barcode.barcode.trim() !== '');
        setIsFormValid(isValid);
    };

    useEffect(() => {
        checkFormValidity();
    }, [itemCode, libelle, prix, dateDebut, dateFin, barcodesState]);

    const { data: modePaiementAssocieList } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Mode of Payment',
            fields: ['`tabMode of Payment`.`name`'],
            filters: [['Mode of Payment', 'mode_of_payment_for_item_financier', '=', 1]]
        },
        'POST'
    );

    const { data: divisionList } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Warehouse',
            fields: ['name']
        },
        'POST'
    );

    const handleListAccount = () => {
        setLoadingAccount(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({
                doctype: 'Account',
                fields: [
                    '`tabAccount`.`name`',
                    '`tabAccount`.`owner`',
                    '`tabAccount`.`creation`',
                    '`tabAccount`.`modified`',
                    '`tabAccount`.`modified_by`',
                    '`tabAccount`.`_user_tags`',
                    '`tabAccount`.`_comments`',
                    '`tabAccount`.`_assign`',
                    '`tabAccount`.`_liked_by`',
                    '`tabAccount`.`docstatus`',
                    '`tabAccount`.`parent`',
                    '`tabAccount`.`parenttype`',
                    '`tabAccount`.`parentfield`',
                    '`tabAccount`.`idx`',
                    '`tabAccount`.`account_name`',
                    '`tabAccount`.`account_number`',
                    '`tabAccount`.`disabled`'
                ],
                filters: [
                    ['Account', 'company', '=', globalDefaults?.default_company],
                    ['Account', 'root_type', '=', typefamilleComptable]
                ]
            })
        })
            .then((res) => res.json())
            .then((data) => {
                setFamilleComptableList(data);
                setLoadingAccount(false);
            });
    };
    const handleListJournal = () => {
        setLoadingAccount(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({
                doctype: 'Journal Entry',
                fields: [
                    '`tabJournal Entry`.`name`',
                    '`tabJournal Entry`.`owner`',
                    '`tabJournal Entry`.`creation`',
                    '`tabJournal Entry`.`modified`',
                    '`tabJournal Entry`.`modified_by`',
                    '`tabJournal Entry`.`_user_tags`',
                    '`tabJournal Entry`.`_comments`',
                    '`tabJournal Entry`.`_assign`',
                    '`tabJournal Entry`.`_liked_by`',
                    '`tabJournal Entry`.`docstatus`',
                    '`tabJournal Entry`.`parent`',
                    '`tabJournal Entry`.`parenttype`',
                    '`tabJournal Entry`.`parentfield`',
                    '`tabJournal Entry`.`idx`',
                    '`tabJournal Entry`.`cheque_no`',
                    '`tabJournal Entry`.`total_debit`',
                    '`tabJournal Entry`.`total_amount`',
                    '`tabJournal Entry`.`title`',
                    '`tabJournal Entry`.`voucher_type`',
                    '`tabJournal Entry`.`posting_date`',
                    '`tabJournal Entry`.`company`',
                    '`tabJournal Entry`.`user_remark`',
                    '`tabJournal Entry`.`total_amount_currency`'
                ],
                filters: [['Journal Entry', 'company', '=', globalDefaults?.company]]
            })
        })
            .then((res) => res.json())
            .then((data) => {
                setJournalComptableList(data);

                setLoadingAccount(false);
            });
    };

    function extractStringBetweenWords(input, word1, word2) {
        const regex = new RegExp(`${word1}(.*?)${word2}`, 'i'); // 'i' pour insensible à la casse
        const match = input.match(regex); // Utilise la regex pour chercher une correspondance

        if (match && match[1]) {
            return match[1].trim(); // Retourne la chaîne entre les deux mots
        }
        return ''; // Retourne une chaîne vide si aucune correspondance n'est trouvée
    }

    useEffect(() => {
        handleListAccount();
        handleListJournal();
    }, [typefamilleComptable]);

    const handleAddFinancier = async () => {
        setAttemptedSubmit(true);
        const newErrors = {};
        required.forEach((field) => {
            const value = eval(field);
            newErrors[field] = validateField(field, value);
        });
        setErrors(newErrors);
        const isValid =
            Object.values(newErrors).every((error) => !error) &&
            barcodesState.length > 0 &&
            barcodesState.every((barcode) => barcode.barcode.trim() !== '');

        if (isValid) {
            setLoadingButton(true);
            const data = {
                doc: {
                    docstatus: 0,
                    doctype: 'Item Financier',
                    name: 'new-item-financier-1',
                    disabled: enabled,
                    __islocal: 1,
                    type: typeProduct,
                    division_commerciale: division,
                    rattachement_à_un_client: rattachement,
                    journal_comptable: journalComptable,
                    type_article_financier: typeArticle,
                    type_famille_comptable: typefamilleComptable,
                    type_d_utilisation: typeUtilisation,
                    type_de_demande_de_crédit: typeDemandeCredit,
                    utilisation_avec_d_autre_article: checkboxState.utilisation,
                    utilisable_pour_la_fidélité: checkboxState.fidelite,
                    commentaire: commentaire,
                    compté_dans_les_indices: checkboxState.indices,
                    compté_dans_le_cumul_des_quantités: checkboxState.quantites,
                    envoi_du_montant_au_tpe: checkboxState.tpe,
                    remisable_en_ligne: checkboxState.remisable,
                    ignoré_par_mimprimante_fiscale: checkboxState.imprimeFiscale,
                    pré_enregistré: checkboxState.preEnregistre,
                    attribution_du_numéro_de_bon: attribution,
                    code: itemCode,
                    libelle: libelle,
                    prix: prix,
                    barcodes: barcodesState.map((el, index) => ({
                        docstatus: 0,
                        doctype: 'Item Barcode',
                        name: `new-item-barcode-${index + 1}`,
                        barcode_type: 'EAN',
                        barcode_type_name: el.barcode_type_name,
                        parent: 'new-item-financier-1',
                        parentfield: 'barcodes',
                        parenttype: 'Item Financier',
                        idx: index + 1,
                        barcode: el.barcode
                    })),
                    famille_comptable: familleComptable,
                    compte_comptable: compteComptable,
                    mode_de_paiement_associé: modePaiementAssocie,
                    date_de_début: dateDebut,
                    jusquau: dateFin
                },
                action: 'Save'
            };
            fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify(data)
            })
                .then((res) => {
                    setLoadingButton(false);
                    if (!res.ok) {
                        dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    }
                    return res.json();
                })
                .then((data) => {
                    if (data?.success === false) {
                        dispatch(
                            SetNotification({
                                code: 'warning',
                                message:
                                    t('common:itemFinancier') +
                                    extractStringBetweenWords(formatErpResponse(data?.message), 'Financier ', 'already') +
                                    t('common:alreadyExists')
                            })
                        );
                    } else {
                        dispatch(SetNotification({ code: 'success', message: t('common:prodFinSuccess') }));
                        navigate('/pim/products-financier');
                    }
                });
        }
    };

    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            {t('columns:Produit_financier')}
        </Box>
    );

    return (
        <Box>
            <MainCard title={title}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                        {t('columns:Caractéristiques')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControlLabel
                            control={<Checkbox checked={enabled} onChange={(e) => setEnabled(e.target.checked)} />}
                            label={t('core:disable')}
                        />
                        <Grid container spacing={2} style={{ marginTop: '20px' }}>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    id="itemCode"
                                    label={t('products:item_code')}
                                    required
                                    fullWidth
                                    value={itemCode}
                                    onChange={(e) => handleInputChange('itemCode', e.target.value)}
                                    error={!!errors.itemCode}
                                    helperText={errors.itemCode}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    id="libelle"
                                    label={t('columns:libelle')}
                                    required
                                    fullWidth
                                    value={libelle}
                                    onChange={(e) => handleInputChange('libelle', e.target.value)}
                                    error={!!errors.libelle}
                                    helperText={errors.libelle}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label={t('columns:commentaire')}
                                    multiline
                                    fullWidth
                                    onChange={(e) => setCommentaire(e.target.value)}
                                    rows={4}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={checkboxState.utilisation} onChange={handleCheckboxChange('utilisation')} />
                                    }
                                    label={t('columns:utilisation')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                    control={<Checkbox checked={checkboxState.fidelite} onChange={handleCheckboxChange('fidelite')} />}
                                    label={t('columns:fidelite')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                    control={<Checkbox checked={checkboxState.indices} onChange={handleCheckboxChange('indices')} />}
                                    label={t('columns:indices')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                    control={<Checkbox checked={checkboxState.quantites} onChange={handleCheckboxChange('quantites')} />}
                                    label={t('columns:quantites')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                    control={<Checkbox checked={checkboxState.tpe} onChange={handleCheckboxChange('tpe')} />}
                                    label={t('columns:tpe')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                    control={<Checkbox checked={checkboxState.remisable} onChange={handleCheckboxChange('remisable')} />}
                                    label={t('columns:remisable')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkboxState.imprimeFiscale}
                                            onChange={handleCheckboxChange('imprimeFiscale')}
                                        />
                                    }
                                    label={t('columns:imprimeFiscale')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={checkboxState.preEnregistre} onChange={handleCheckboxChange('preEnregistre')} />
                                    }
                                    label={t('columns:preEnregistre')}
                                />
                            </Grid>
                            {checkboxState.preEnregistre && (
                                <Grid item xs={12} sm={6} md={6} marginLeft={50}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={t('columns:price')}
                                        fullWidth
                                        onChange={(e) => {
                                            const value = e.target.value.trim();
                                            setPrix(value);
                                            checkFormValidity();
                                        }}
                                        maxRows={4}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} sm={6} md={6}>
                                <SelectForm
                                    label={t('columns:division')}
                                    name="division"
                                    required
                                    value={division}
                                    handleChange={(e) => handleInputChange('division', e.target.value)}
                                    data={divisionList}
                                    propToRender={'name'}
                                    error={!!errors.division}
                                    helper={errors.division}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <SelectForm
                                    label={t('columns:typeProduct')}
                                    name="type"
                                    required
                                    value={typeProduct}
                                    handleChange={(e) => setTypeProduct(e.target.value)}
                                    data={['Carte cadeau', 'Bon d’achat', 'Avoir (crédit note)', 'A compte (Déposit)']}
                                    error={!!errors.typeProduct}
                                    helper={errors.typeProduct}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <SelectForm
                                    label={t('columns:rattachement')}
                                    name="type"
                                    required
                                    value={rattachement}
                                    handleChange={(e) => setRattachement(e.target.value)}
                                    data={['facultatif', 'interdit', 'obligatoire']}
                                    error={!!errors.rattachement}
                                    helper={errors.rattachement}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <SelectForm
                                    label={t('columns:typeArticle')}
                                    name="type"
                                    required
                                    value={typeArticle}
                                    handleChange={(e) => setTypeArticle(e.target.value)}
                                    data={[
                                        'Acquisition d’une carte cadeau',
                                        'Acquisition Bon d’achat',
                                        'Acquisition Bon d’achat de fidélité'
                                    ]}
                                    error={!!errors.typeArticle}
                                    helper={errors.typeArticle}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <SelectForm
                                    label={t('columns:typeUtilisation')}
                                    name="type"
                                    required
                                    value={typeUtilisation}
                                    handleChange={(e) => setTypeUtilisation(e.target.value)}
                                    data={['mode de paiement']}
                                    error={!!errors.typeUtilisation}
                                    helper={errors.typeUtilisation}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <SelectForm
                                    label={t('columns:typeDemandeCredit')}
                                    name="type"
                                    required
                                    value={typeDemandeCredit}
                                    handleChange={(e) => setTypeDemandeCredit(e.target.value)}
                                    data={['activation', 'émission', 'rechargement']}
                                    error={!!errors.typeDemandeCredit}
                                    helper={errors.typeDemandeCredit}
                                />
                            </Grid>
                            *
                            <Grid item xs={12}>
                                <BarcodesForm
                                    barcodesState={barcodesState}
                                    setBarcodesState={setBarcodesState}
                                    automatic={globalAutomatic}
                                />
                                {(barcodesState.length === 0 || barcodesState.some((barcode) => !barcode.barcode.trim())) && (
                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                        <ErrorOutlineIcon
                                            sx={{
                                                color: theme.palette.error.main,
                                                mr: 1,
                                                fontSize: '1.2rem'
                                            }}
                                        />
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: theme.palette.error.main,
                                                fontWeight: 'medium'
                                            }}
                                        >
                                            {t('products:barcodeRequired')}
                                        </Typography>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                        {t('columns:Comptabilisation')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                                <SelectForm
                                    label={t('columns:typefamilleComptable')}
                                    name="name"
                                    value={typefamilleComptable}
                                    handleChange={(e) => setTypeFamilleComptable(e.target.value)}
                                    data={['Asset', 'Liability', 'Income', 'Expense', 'Equity']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <SelectForm
                                    label={t('columns:familleComptable')}
                                    name="name"
                                    value={familleComptable}
                                    handleChange={(e) => setFamilleComptable(e.target.value)}
                                    data={familleComptableList}
                                    propToRender={'name'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <SelectForm
                                    label={t('columns:journalComptable')}
                                    name="type"
                                    value={journalComptable}
                                    handleChange={(e) => setJournalComptable(e.target.value)}
                                    data={journalComptableList}
                                    propToRender={'title'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <SelectForm
                                    label={t('columns:compteComptable')}
                                    name="type"
                                    value={compteComptable}
                                    handleChange={(e) => setCompteComptable(e.target.value)}
                                    data={['activation', 'émission', 'rechargement']}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                        {t('columns:Réglementsenattente')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2} style={{ marginTop: '10px' }}>
                            <Grid item xs={12} sm={6} md={6}>
                                <SelectForm
                                    label={t('columns:attribution')}
                                    name="type"
                                    value={attribution}
                                    handleChange={(e) => setAttribution(e.target.value)}
                                    data={['non', 'automatique', 'manuelle', 'depuis un lecteur MSR']}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <SelectForm
                                    label={t('columns:modePaiementAssocie')}
                                    name="name"
                                    value={modePaiementAssocie}
                                    handleChange={(e) => setModePaiementAssocie(e.target.value)}
                                    data={modePaiementAssocieList}
                                    propToRender={'name'}
                                />
                            </Grid>
                            <Box sx={modalStyle}></Box>

                            <Grid item xs={12} sm={6} md={6}>
                                <FormDateTime
                                    required
                                    type="date"
                                    label={t('columns:dateDebut')}
                                    name="dateDebut"
                                    value={dateDebut}
                                    error={!!errors.dateDebut}
                                    helper={errors.dateDebut}
                                    handleChange={(e) => {
                                        const value = e.target.value;
                                        handleInputChange('dateDebut', value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormDateTime
                                    required
                                    type="date"
                                    label={t('columns:dateFin')}
                                    error={!!errors.dateFin}
                                    helper={errors.dateFin}
                                    name="dateFin"
                                    value={dateFin}
                                    handleChange={(e) => {
                                        const value = e.target.value;
                                        handleInputChange('dateFin', value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Grid container justifyContent="center" spacing={4}>
                    <Grid item mt={3}>
                        <LoadingButton
                            loading={loadingButton}
                            variant="contained"
                            size="large"
                            color="blue"
                            onClick={handleAddFinancier}
                            disabled={!isFormValid}
                        >
                            {t('common:save')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </MainCard>
        </Box>
    );
};

export default ProductFinancierAdd;
