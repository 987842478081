import React, { useState, useRef } from 'react';
import useFetch from 'hooks/useFetch';
import DataRender from 'ui-component/tables/DataRender';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, ClickAwayListener, Grid, Modal, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { SetBulkEditIds, SetBulkEditFields, SetNotification } from 'store/services/api';
import MainCard from 'ui-component/cards/MainCard';
import { DataGrid } from '@mui/x-data-grid';
import { frLocal } from 'utils/dataGridFRLocal';
import { modalStyle } from 'views/utilities/ModalStyle';
import { LoadingButton } from '@mui/lab';
import { formatErpResponse, formatServerMessages } from 'utils/utils';
import ServerMSG from 'ui-component/cards/ServerMSG';
import { useStyles } from 'views/utilities/Style';
import { useTranslation } from 'react-i18next';
import DataImporter from 'views/products/productManagement/components/DataImporter';
import PrintItemListTemplate from 'views/products/productManagement/components/PrintItemListTemplate';
import DetailModal from 'views/products/productPrestation/components/DetailModal';

const ProductPrestationList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const componentRef = useRef();
    const classes = useStyles();
    const [modals, setModals] = useState(0);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [loadingButton, setLoadingButton] = useState(false);
    const [serverMessages, setServerMessages] = useState([]);
    const [detailModalOpen, setDetailModalOpen] = useState(false);

    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);

    const [open, setOpen] = useState(false);
    const handleOpen = (modal) => {
        setOpen(true);
        setModals(modal);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleBulkEditClose = () => {
        setOpen(false);
        dispatch(SetBulkEditFields([]));
    };
    const getSelectedRows = () => {
        let result = [];
        selectionModel.map((id) => {
            result.push(productsData.find((el) => el.name === id));
        });
        return result;
    };

    const itemsReqData = {
        doctype: 'Item',
        fields: [
            '`tabItem`.`name`',
            '`tabItem`.`item_code`',
            '`tabItem`.`item_name`',
            '`tabItem`.`item_group`',
            '`tabItem`.`disabled`',
            '`tabItem`.`creation`'
        ],
        filters: [['Item', 'item_group', '=', 'Services']]
    };
    const { data: productsData, isPending, refetch } = useFetch(`/api/get-list`, itemsReqData, 'POST');

    const handleEdit = (row) => {
        navigate(`/pim/edit-product-provision/${row}`);
    };
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleDelete = (row) => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Item', name: row[0] })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    const errorMessage = formatErpResponse(data?.message);
                    if (errorMessage.includes('Cannot delete or cancel')) {
                        dispatch(
                            SetNotification({
                                code: 'warning',
                                message: t('common:item_is_Linked', { item: row[0] })
                            })
                        );
                    } else {
                        dispatch(SetNotification({ code: 'warning', message: errorMessage }));
                    }
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('columns:prod_prest_delete') }));
                    refetch({});
                    handleClose();
                }
            });
    };

    const deleteConfirm = (modals) => (
        <Box sx={modalStyle}>
            <MainCard divider title={t('common:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {modals === 1 ? (
                        <>
                            {t('common:deletePermanently')} <strong>{selectionModel}</strong>
                        </>
                    ) : (
                        <>
                            {t('common:deletePermanently')}
                            <strong>
                                {Array.isArray(selectionModel) && selectionModel.length} {t('common:prdcts')}
                            </strong>
                        </>
                    )}
                    ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleClose}>
                                    {t('common:non')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() =>
                                        modals === 1 ? handleDelete(selectionModel) : modals === 2 ? handleBulkDelete(selectionModel) : {}
                                    }
                                    variant="contained"
                                    loading={loadingButton}
                                >
                                    {t('common:oui')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    // bulk edit
    // >>>

    const [selectionFieldsModel, setSelectionFieldsModel] = useState([]);

    const columnsImport = [
        'Item Code',
        'Item Name',
        'Item Group',
        'Description',
        'Standard Selling Rate',
        'Weight Per Unit',
        'Weight UOM',
        'Default Warehouse (Item Defaults)',
        'Company (Item Defaults)',
        'Default Price List (Item Defaults)',
        'Item Tax Template (Taxes)',
        'Default Unit of Measure',
        'Opening Stock'
    ];

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1, visible: false },
        {
            field: 'fieldName',
            headerName: t('common:fieldName'),
            flex: 1
        }
    ];

    const rows = [
        { id: 'item_name', fieldName: t('products:productName') },
        { id: 'item_group', fieldName: t('products:productCategory') },
        { id: 'description', fieldName: t('common:description') },
        { id: 'valuation_rate', fieldName: t('dashboard:valuationRate') },
        { id: 'standard_selling_rate', fieldName: t('products:StandardSellingRate') },
        { id: 'price_list_rate', fieldName: t('products:Taux_de_la_liste_de_prix') },
        { id: 'brand', fieldName: t('products:brand') },
        { id: 'disabled', fieldName: t('products:Statut') }
        // { id: 'opening_stock', fieldName: t('products:opening_stock') }
        // { id: 'default_unit_of_measure', fieldName: 'Unité de mesure par défaut' },
        // { id: 'weight_uom', fieldName: 'Unité de mesure du poids' },
        // { id: 'weight_per_unit', fieldName: 'Poids par unité' },
        // { id: 'safety_stock', fieldName: 'Stock de sécurité' },
        // { id: 'max_discount', fieldName: 'Réduction maximale (%)' },
        // { id: 'item_code', fieldName: 'Code De Produit' },
    ];

    const handleOpenDetailModal = (row) => {
        setSelectedRow(row);
        setDetailModalOpen(true);
    };

    const handleCloseDetailModal = () => {
        setDetailModalOpen(false);
        setSelectedRow(null);
    };

    const handleBulkEdit = () => {
        dispatch(SetBulkEditIds([]));
        dispatch(SetBulkEditIds(selectionModel));

        dispatch(SetBulkEditFields([]));
        dispatch(SetBulkEditFields(selectionFieldsModel));

        navigate('/pim/products-bulk-edit');
    };

    const fieldSelection = (
        <Box display={'grid'} sx={{ placeItems: 'center' }}>
            <ClickAwayListener onClickAway={() => handleBulkEditClose()}>
                <Box width={500} mt={'5%'}>
                    <MainCard divider title={t('common:editer-en-masse')}>
                        <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                            {t('products:msg')}
                        </Typography>
                        <Box height={400} width={'100%'} mt={3}>
                            <DataGrid
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            id: false
                                        }
                                    }
                                }}
                                rows={rows}
                                columns={columns}
                                checkboxSelection
                                getRowId={(row) => row.id}
                                localeText={frLocal}
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                rowSelectionModel={selectionFieldsModel}
                                onRowSelectionModelChange={(newSelectionModel) => {
                                    setSelectionFieldsModel(newSelectionModel);
                                }}
                            />
                        </Box>
                        <Box display={'flex'} justifyContent={'flex-end'} mt={0}>
                            <Box>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Button variant="outlined" color="blue" size="large" onClick={handleBulkEditClose}>
                                            {t('common:cancel')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button color="blue" size="large" onClick={() => handleBulkEdit()} variant="contained">
                                            {t('common:contine')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </MainCard>
                </Box>
            </ClickAwayListener>
        </Box>
    );

    // bulk delete
    // >>>

    const handleBulkDelete = (rows) => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-items`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Item', items: rows })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?._server_messages) {
                    setServerMessages(formatServerMessages(data?._server_messages));
                    refetch({});
                    handleClose();
                    handleOpen(3);
                    return;
                }
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                    return;
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Les produits ont été supprimés avec succès' }));
                    refetch({});
                    handleClose();
                }
            });
    };

    const serverMessagesWarning = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('products:server_messages')}>
                <Box>
                    {serverMessages.map((el, i) => {
                        const isDeleteMessage = el.message.includes('Cannot delete or cancel because Item');
                        let itemName = '';
                        if (isDeleteMessage) {
                            const matches = el.message.match(/Item (.*?) is linked/);
                            itemName = matches ? matches[1] : '';
                        }

                        const message = isDeleteMessage && itemName ? t('common:item_is_Linked', { item: itemName }) : el.message;

                        return (
                            <Box mt={1} key={i}>
                                <ServerMSG message={message} />
                            </Box>
                        );
                    })}
                </Box>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Button variant="outlined" color="blue" size="large" onClick={handleClose}>
                            {t('core:closebutton')}
                        </Button>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const dataImporter = (
        <Box sx={modalStyle}>
            <MainCard divider title={'Import'}>
                <DataImporter refetch={refetch} handleClose={handleClose} columns={columnsImport} doctype="Item Prestation" />
            </MainCard>
        </Box>
    );

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Grid
                spacing={isSmallScreen && 5}
                sx={{ flexGrow: 2, flexDirection: 'row', alignItems: { xs: 'center', sm: 'center' } }}
                container
            >
                <Grid item md>
                    {t('products:listePrestations')}
                </Grid>
                <Grid item xs display="flex" justifyContent="flex-end">
                    <Box>
                        <Grid
                            container
                            direction={isSmallScreen ? 'column' : 'row'}
                            spacing={2}
                            alignItems={isSmallScreen ? 'flex-end' : 'flex-end'}
                        >
                            {Array.isArray(selectionModel) && selectionModel?.length !== 0 && (
                                <Grid item xs>
                                    {selectionModel.length === 1 ? (
                                        <Button
                                            startIcon={<DeleteIcon color="error" />}
                                            variant="outlined"
                                            color="error"
                                            onClick={() => handleOpen(1)}
                                            sx={{ marginTop: -3 }}
                                        >
                                            {t('common:delete')}
                                        </Button>
                                    ) : (
                                        <Button
                                            startIcon={<DeleteIcon color="error" />}
                                            variant="outlined"
                                            color="error"
                                            onClick={() => handleOpen(2)}
                                            sx={{ marginTop: -3 }}
                                        >
                                            {t('common:delete')}
                                        </Button>
                                    )}
                                </Grid>
                            )}
                            {Array.isArray(selectionModel) && selectionModel?.length !== 0 && (
                                <Grid item xs>
                                    {selectionModel.length === 1 ? (
                                        <Button
                                            startIcon={<EditIcon />}
                                            variant="outlined"
                                            color="blue"
                                            onClick={() => handleEdit(selectionModel)}
                                            sx={{ marginTop: -3 }}
                                        >
                                            {t('common:edit')}
                                        </Button>
                                    ) : (
                                        <Button
                                            startIcon={<EditIcon />}
                                            variant="outlined"
                                            color="blue"
                                            onClick={() => handleOpen(0)}
                                            sx={{ marginTop: -3 }}
                                        >
                                            {t('common:edit')}
                                        </Button>
                                    )}
                                </Grid>
                            )}
                            <Grid item xs>
                                <Button
                                    startIcon={<AddCircleIcon />}
                                    variant="outlined"
                                    color="blue"
                                    onClick={() => navigate('/pim/add-products-service')}
                                    sx={{ marginTop: -3 }}
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    {t('products:addPrestation')}
                                </Button>
                            </Grid>
                            <Grid item xs>
                                <Button variant="text" color="blue" onClick={() => handleOpen(4)} sx={{ marginTop: -3 }}>
                                    Import
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );

    return (
        <Box>
            <DataRender
                refetch={refetch}
                isPrintable
                componentRef={componentRef}
                title={title}
                data={productsData}
                loading={isPending}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                hasCheckBoxes
                notshow={['name']}
                handleOpenDetailModal={handleOpenDetailModal}
                Visualize={true}
            />
            <Box className={classes.print}>
                <PrintItemListTemplate
                    ref={componentRef}
                    selectionModel={selectionModel}
                    data={selectionModel.length != 0 ? getSelectedRows() : productsData}
                />
            </Box>
            <Modal onClose={handleClose} open={open}>
                {modals === 0 ? (
                    fieldSelection
                ) : modals === 1 ? (
                    deleteConfirm(1)
                ) : modals === 2 ? (
                    deleteConfirm(2)
                ) : modals === 3 ? (
                    serverMessagesWarning
                ) : modals === 4 ? (
                    dataImporter
                ) : (
                    <div>Oops</div>
                )}
            </Modal>
            <DetailModal open={detailModalOpen} onClose={handleCloseDetailModal} row={selectedRow} />
        </Box>
    );
};
export default ProductPrestationList;
