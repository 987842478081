import React, { useMemo, useState } from 'react';
import { Modal, Box, Typography, Button, Divider, IconButton, Grid, Paper, Tabs, Tab } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useFetch from 'hooks/useFetch';

const DetailModal = ({ open, onClose, row }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(0);

    const { data: itemdefault, isPending: ItemDefaultPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Item Default', fields: ['*'], filters: [['Item Default', 'parentfield', '=', 'item_defaults']] },
        'POST'
    );

    const filteredItemDefault = useMemo(() => {
        if (!itemdefault || !row) return null;
        return itemdefault.find((item) => item.parent === row.name);
    }, [itemdefault, row]);

    const { data: items, isPending: ItemsPending } = useFetch(`/api/get-list`, { doctype: 'Item', fields: ['*'] }, 'POST');

    const filteredItems = useMemo(() => {
        if (!items || !row) return null;
        return items.find((item) => item.name === row.name);
    }, [items, row]);

    const { data: itemsprice, isPending: ItemsPricePending } = useFetch(`/api/get-list`, { doctype: 'Item Price', fields: ['*'] }, 'POST');

    const filteredItemPrices = useMemo(() => {
        if (!itemsprice || !filteredItems) return [];
        return itemsprice.filter((price) => price.item_code === filteredItems.item_code);
    }, [itemsprice, filteredItems]);

    const { data: reorders, isPending: ReordersPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Item Reorder', fields: ['*'], filters: [['Item Reorder', 'parentfield', '=', 'reorder_levels']] },
        'POST'
    );

    const filteredReorders = useMemo(() => {
        if (!reorders || !row) return [];
        return reorders.filter((item) => item.parent === row.name);
    }, [reorders, row]);

    const { data: supplierItems, isPending: SupplierItemsPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Item Supplier', fields: ['*'], filters: [['Item Supplier', 'parentfield', '=', 'supplier_items']] },
        'POST'
    );

    const filteredSupplierItems = useMemo(() => {
        if (!supplierItems || !row) return [];
        return supplierItems.filter((item) => item.parent === row.name);
    }, [supplierItems, row]);

    const { data: famillies, isPending: FamilliesPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Item Famille', fields: ['*'], filters: [['Item Famille', 'parentfield', '=', 'famillies']] },
        'POST'
    );

    const filteredFamillies = useMemo(() => {
        if (!famillies || !row) return [];
        return famillies.filter((item) => item.parent === row.name);
    }, [famillies, row]);

    const { data: sousfamillies, isPending: SousFamilliesPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Item SousFamille', fields: ['*'], filters: [['Item SousFamille', 'parentfield', '=', 'sousfamillies']] },
        'POST'
    );

    const filteredSousFamillies = useMemo(() => {
        if (!sousfamillies || !row) return [];
        return sousfamillies.filter((item) => item.parent === row.name);
    }, [sousfamillies, row]);

    const { data: tablelibres, isPending: TableLibresPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Item Table Libre',
            fields: ['*'],
            filters: [['Item Table Libre', 'parentfield', '=', 'tablelibres']]
        },
        'POST'
    );

    const filteredTableLibres = useMemo(() => {
        if (!tablelibres || !row) return [];
        return tablelibres.filter((item) => item.parent === row.name);
    }, [tablelibres, row]);

    const { data: soustablelibres, isPending: SousTableLibresPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Item SousTable Libre', fields: ['*'], filters: [['Item SousTable Libre', 'parentfield', '=', 'soustablelibres']] },
        'POST'
    );

    const filteredSousTableLibres = useMemo(() => {
        if (!soustablelibres || !row) return [];
        return soustablelibres.filter((item) => item.parent === row.name);
    }, [soustablelibres, row]);

    const { data: attributes, isPending: AttributesPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Item Variant Attribute', fields: ['*'], filters: [['Item Variant Attribute', 'parentfield', '=', 'attributes']] },
        'POST'
    );

    const filteredAttributes = useMemo(() => {
        if (!attributes || !row) return [];
        return attributes.filter((item) => item.parent === row.name);
    }, [attributes, row]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    if (!row) return null;

    const sections = [
        { id: 'basic_info', label: 'products:basic_section' },
        { id: 'pricing', label: 'products:pricing_section' },
        //{ id: 'reorder_section', label: 'products:reassort' },
        //{ id: 'inventory', label: 'products:inventory' },
        //{ id: 'sales_details', label: 'products:detailVente' },
        //{ id: 'serial_nos_and_batches_section', label: 'products:numSerieLot' },
        { id: 'famille_sousfamille', label: 'products:famille' },
        { id: 'table_libre', label: 'products:table_libre' }
    ];

    const renderBasicInfo = () => (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Box
                    sx={{
                        bgcolor: 'grey.50',
                        p: 2,
                        borderRadius: 1,
                        border: 1,
                        borderColor: 'grey.200',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <img
                        src={
                            filteredItems?.image
                                ? `${process.env.REACT_APP_IMAGE_URL}${filteredItems.image}`
                                : 'https://upload.wikimedia.org/wikipedia/fr/4/45/CS_Sfaxien.gif'
                        }
                        alt={filteredItems?.name || 'Product image'}
                        style={{ maxWidth: '100%', maxHeight: '300px', objectFit: 'contain' }}
                    />
                </Box>
            </Grid>
            {[
                { label: 'columns:libelle', value: filteredItems?.name },
                { label: 'products:productCode', value: filteredItems?.item_code },
                { label: 'products:productCategory', value: row?.item_group },
                { label: 'products:subProductCategory', value: filteredItems?.sousitem_group },
                { label: 'products:brand', value: filteredItems?.brand },
                { label: 'products:collection', value: filteredItems?.item_collection },
                { label: 'products:stockUOM', value: filteredItems?.stock_uom },
                { label: 'common:description', value: filteredItems?.description },
                { label: 'columns:Barcode', value: filteredItems?.barcode }
            ].map(({ label, value }) => (
                <Grid item xs={12} sm={6} key={label}>
                    <Box sx={{ bgcolor: 'grey.50', p: 2, borderRadius: 1, border: 1, borderColor: 'grey.200' }}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            {t(label)}
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            {value || '-'}
                        </Typography>
                    </Box>
                </Grid>
            ))}
            {renderAttributes()}
        </Grid>
    );

    const renderPricing = () => (
        <Grid container spacing={3}>
            {[
                { label: 'columns:currency', value: filteredItems?.currency },
                { label: 'core:taxrate', value: filteredItems?.item_tax_category },
                { label: 'products:Prix_de_vente_HT', value: filteredItems?.standard_rate },
                { label: 'dashboard:standardRate', value: filteredItems?.prix_de_vente_ht },
                { label: 'products:PrixUnitaireTTC', value: filteredItems?.prix_unitaire_ttc },
                { label: 'products:PrixAchatHT', value: filteredItems?.prix_achat_ht },
                { label: 'products:PrixDeRevient', value: filteredItems?.prix_de_revient },
                { label: 'core:priceelistselling', value: filteredItemDefault?.default_price_list },
                //{ label: 'dashboard:valuationRate', value: filteredItems?.valuation_rate },
                { label: 'products:lastPurchaseRate', value: filteredItems?.last_purchase_rate }
            ].map(({ label, value }) => (
                <Grid item xs={12} sm={6} key={label}>
                    <Box sx={{ bgcolor: 'grey.50', p: 2, borderRadius: 1, border: 1, borderColor: 'grey.200' }}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            {t(label)}
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            {value || '-'}
                        </Typography>
                    </Box>
                </Grid>
            ))}
            {renderPriceList()}
        </Grid>
    );

    const renderInventory = () => (
        <Grid container spacing={3}>
            {[
                { label: 'products:weightUOM', value: filteredItems?.weight_uom },
                { label: 'products:warrantyPeriod', value: filteredItems?.warranty_period },
                { label: 'products:defaultMaterialRequestType', value: filteredItems?.default_material_request_type },
                { label: 'products:shelfLifeInDays', value: filteredItems?.shelf_life_in_days },
                { label: 'products:valuationMethod', value: filteredItems?.valuation_method }
            ].map(({ label, value }) => (
                <Grid item xs={12} sm={6} key={label}>
                    <Box sx={{ bgcolor: 'grey.50', p: 2, borderRadius: 1, border: 1, borderColor: 'grey.200' }}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            {t(label)}
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            {value || '-'}
                        </Typography>
                    </Box>
                </Grid>
            ))}
        </Grid>
    );

    const renderNumSerieLot = () => (
        <Grid container spacing={3}>
            {[
                {
                    label: 'products:hasSerialNo',
                    value: filteredItems?.has_serial_no !== undefined ? (filteredItems.has_serial_no ? t('core:yes') : t('core:no')) : '-'
                },
                {
                    label: 'products:hasBatchNo',
                    value: filteredItems?.has_batch_no !== undefined ? (filteredItems.has_batch_no ? t('core:yes') : t('core:no')) : '-'
                }
            ].map(({ label, value }) => (
                <Grid item xs={12} sm={6} key={label}>
                    <Box sx={{ bgcolor: 'grey.50', p: 2, borderRadius: 1, border: 1, borderColor: 'grey.200' }}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            {t(label)}
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            {value || '-'}
                        </Typography>
                    </Box>
                </Grid>
            ))}
        </Grid>
    );

    const renderSalesDetails = () => (
        <Grid container spacing={3}>
            {[
                { label: 'products:maxDiscount', value: filteredItems?.max_discount },
                { label: 'products:defaultWarehouse', value: filteredItemDefault?.default_warehouse },
                {
                    label: 'products:isSalesItem',
                    value: filteredItems?.is_sales_item !== undefined ? (filteredItems.is_sales_item ? t('core:yes') : t('core:no')) : '-'
                },
                { label: 'products:Supplier', value: filteredItems?.supplier }
            ].map(({ label, value }) => (
                <Grid item xs={12} sm={6} key={label}>
                    <Box sx={{ bgcolor: 'grey.50', p: 2, borderRadius: 1, border: 1, borderColor: 'grey.200' }}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            {t(label)}
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            {value || '-'}
                        </Typography>
                    </Box>
                </Grid>
            ))}
            {filteredSupplierItems.length > 0 && (
                <Grid item xs={12}>
                    <Typography variant="h6" component="h3" fontWeight="bold" color="primary" mb={2}>
                        {t('core:supplierDetails')}
                    </Typography>
                    <Grid container spacing={3}>
                        {filteredSupplierItems.map((supplierItem, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <Box sx={{ bgcolor: 'grey.50', p: 2, borderRadius: 1, border: 1, borderColor: 'grey.200' }}>
                                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                        {t('core:supplier')}
                                    </Typography>
                                    <Typography variant="body1" fontWeight="medium">
                                        {supplierItem.supplier}
                                    </Typography>
                                    <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ mt: 1 }}>
                                        {t('core:supplierPN')}
                                    </Typography>
                                    <Typography variant="body1" fontWeight="medium">
                                        {supplierItem.supplier_part_no}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );

    const renderPriceList = () =>
        filteredItemPrices.length > 0 && (
            <Grid item xs={12}>
                <Typography variant="h6" component="h3" fontWeight="bold" color="primary" mb={2}>
                    {t('products:price_list')}
                </Typography>
                <Grid container spacing={3}>
                    {filteredItemPrices.map((price, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                            <Box sx={{ bgcolor: 'grey.50', p: 2, borderRadius: 1, border: 1, borderColor: 'grey.200' }}>
                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                    {t('core:itemPriceList')}
                                </Typography>
                                <Typography variant="body1" fontWeight="medium">
                                    {price.price_list}
                                </Typography>
                                <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ mt: 1 }}>
                                    {t('core:price')}
                                </Typography>
                                <Typography variant="body1" fontWeight="medium">
                                    {price.price_list_rate} {price.currency}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        );

    const renderReorderLevels = () => (
        <Grid container spacing={3}>
            {[
                { label: 'products:leadTimeDays', value: filteredItems?.lead_time_days },
                { label: 'products:minOrderQty', value: filteredItems?.min_order_qty },
                { label: 'products:purchaseUOM', value: filteredItems?.purchase_uom },
                { label: 'products:safetyStock', value: filteredItems?.safety_stock },
                {
                    label: 'products:AllowPurchase',
                    value:
                        filteredItems?.is_purchase_item !== undefined
                            ? filteredItems.is_purchase_item
                                ? t('core:yes')
                                : t('core:no')
                            : '-'
                }
            ].map(({ label, value }) => (
                <Grid item xs={12} sm={6} key={label}>
                    <Box sx={{ bgcolor: 'grey.50', p: 2, borderRadius: 1, border: 1, borderColor: 'grey.200' }}>
                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            {t(label)}
                        </Typography>
                        <Typography variant="body1" fontWeight="medium">
                            {value || '-'}
                        </Typography>
                    </Box>
                </Grid>
            ))}
            {filteredReorders.length > 0 && (
                <Grid item xs={12}>
                    <Typography variant="h6" component="h3" fontWeight="bold" color="primary" mb={2}>
                        {t('products:ReorderLevels')}
                    </Typography>
                    <Grid container spacing={3}>
                        {filteredReorders.map((reorder, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <Box sx={{ bgcolor: 'grey.50', p: 2, borderRadius: 1, border: 1, borderColor: 'grey.200' }}>
                                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                        {t('products:warehouse')}
                                    </Typography>
                                    <Typography variant="body1" fontWeight="medium">
                                        {reorder.warehouse}
                                    </Typography>
                                    <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ mt: 1 }}>
                                        {t('products:reOrderLevel')}
                                    </Typography>
                                    <Typography variant="body1" fontWeight="medium">
                                        {reorder.warehouse_reorder_level}
                                    </Typography>
                                    <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ mt: 1 }}>
                                        {t('products:reOrderQty')}
                                    </Typography>
                                    <Typography variant="body1" fontWeight="medium">
                                        {reorder.warehouse_reorder_qty}
                                    </Typography>
                                    <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ mt: 1 }}>
                                        {t('products:materialRequestType')}
                                    </Typography>
                                    <Typography variant="body1" fontWeight="medium">
                                        {reorder.material_request_type}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );

    const renderFamilleAndSousFamille = () =>
        (filteredFamillies.length > 0 || filteredSousFamillies.length > 0) && (
            <Grid container spacing={3}>
                {filteredFamillies.map((famille, index) => (
                    <Grid item xs={12} sm={6} key={`famille-${index}`}>
                        <Box sx={{ bgcolor: 'grey.50', p: 2, borderRadius: 1, border: 1, borderColor: 'grey.200' }}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                {t('core:famille')}
                            </Typography>
                            <Typography variant="body1" fontWeight="medium">
                                {famille.famille}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
                {filteredSousFamillies.map((sousfamille, index) => (
                    <Grid item xs={12} sm={6} key={`sousfamille-${index}`}>
                        <Box sx={{ bgcolor: 'grey.50', p: 2, borderRadius: 1, border: 1, borderColor: 'grey.200' }}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                {t('core:sousfamille')}
                            </Typography>
                            <Typography variant="body1" fontWeight="medium">
                                {sousfamille.sousfamille}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );

    const renderTableLibre = () =>
        (filteredTableLibres.length > 0 || filteredSousTableLibres.length > 0) && (
            <Grid container spacing={3}>
                {filteredTableLibres.map((table, index) => (
                    <Grid item xs={12} sm={6} key={`table-libre-${index}`}>
                        <Box sx={{ bgcolor: 'grey.50', p: 2, borderRadius: 1, border: 1, borderColor: 'grey.200' }}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                {t('core:libre')}
                            </Typography>
                            <Typography variant="body1" fontWeight="medium">
                                {table.table_libre}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
                {filteredSousTableLibres.map((soustable, index) => (
                    <Grid item xs={12} sm={6} key={`sous-table-libre-${index}`}>
                        <Box sx={{ bgcolor: 'grey.50', p: 2, borderRadius: 1, border: 1, borderColor: 'grey.200' }}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                {t('products:sub_table')}
                            </Typography>
                            <Typography variant="body1" fontWeight="medium">
                                {soustable.soustable_libre}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );

    const renderAttributes = () =>
        filteredAttributes.length > 0 && (
            <Grid item xs={12}>
                <Typography variant="h6" component="h3" fontWeight="bold" color="primary" mb={2}>
                    {t('products:Types_variantes')}
                </Typography>
                <Grid container spacing={3}>
                    {filteredAttributes.map((attr, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                            <Box sx={{ bgcolor: 'grey.50', p: 2, borderRadius: 1, border: 1, borderColor: 'grey.200' }}>
                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                    {t('core:variante_name')}
                                </Typography>
                                <Typography variant="body1" fontWeight="medium">
                                    {attr.attribute}
                                </Typography>
                                {attr.attribute_value && (
                                    <>
                                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                            {t('core:variante_value')}
                                        </Typography>
                                        <Typography variant="body1" fontWeight="medium">
                                            {attr.attribute_value}
                                        </Typography>
                                    </>
                                )}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        );

    const renderContent = () => {
        switch (sections[activeTab].id) {
            case 'basic_info':
                return renderBasicInfo();
            case 'pricing':
                return renderPricing();
            case 'reorder_section':
                return renderReorderLevels();
            case 'inventory':
                return renderInventory();
            case 'serial_nos_and_batches_section':
                return renderNumSerieLot();
            case 'sales_details':
                return renderSalesDetails();
            case 'famille_sousfamille':
                return renderFamilleAndSousFamille();
            case 'table_libre':
                return renderTableLibre();
            default:
                return null;
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Paper
                elevation={24}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    maxWidth: 1300,
                    maxHeight: '90vh',
                    overflow: 'auto',
                    bgcolor: 'background.paper',
                    borderRadius: 2
                }}
            >
                <Box sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography variant="h5" component="h2" fontWeight="bold" color="primary">
                            {t('common:consultation')} {filteredItems?.name}
                        </Typography>
                        <IconButton onClick={onClose} size="large" sx={{ color: 'grey.500' }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ mb: 3 }} />
                    <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
                        {sections.map((section, index) => (
                            <Tab key={section.id} label={t(section.label)} />
                        ))}
                    </Tabs>
                    <Box sx={{ mt: 3 }}>{renderContent()}</Box>
                    <Box display="flex" justifyContent="flex-end" mt={4}>
                        <Button
                            variant="contained"
                            onClick={onClose}
                            sx={{
                                bgcolor: theme.palette.primary.main,
                                '&:hover': {
                                    bgcolor: theme.palette.primary.dark
                                },
                                px: 4,
                                py: 1
                            }}
                        >
                            {t('core:closebutton')}
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Modal>
    );
};

export default DetailModal;
