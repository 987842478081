{
    /* eslint-disable */
}
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
    Button,
    Grid,
    // @ts-ignore
    FormControlLabel,
    // @ts-ignore
    Checkbox,
    // @ts-ignore
    Typography,
    Select,
    FormControl,
    IconButton,
    MenuItem,
    InputLabel,
    Modal
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Stack } from '@mui/system';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import InputTextField from 'ui-component/form/InputTextField';
import { useStyles } from 'views/utilities/Style';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import useGetAxios from 'hooks/useGetAxios';
import Loader from 'ui-component/Loader';
import useFetch from 'hooks/useFetch';
import { formatErpResponse } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PerfectScrollbar from 'react-perfect-scrollbar';
import EditIcon from '@mui/icons-material/Edit';
import RenameForm from '../../ui-component/form/RenameForm';

const BrandForm = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { id } = useParams();
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const [state_rename, setState_rename] = useState({
        doctype: 'Brand',
        docname: id,
        new_name: id,
        enqueue: true,
        merge: 0
    });
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    // @ts-ignore
    const { data: companies, isPending: isCompaniesPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Company', fields: ['`tabCompany`.`name`'] },
        'POST'
    );

    // @ts-ignore
    const { data: warehouses, isPending: isWarehousesPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Warehouse', fields: ['`tabWarehouse`.`name`'] },
        'POST'
    );

    // @ts-ignore
    const { data: pricelists, isPending: isPriceListsPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Price List', fields: ['`tabPrice List`.`name`'] },
        'POST'
    );

    // @ts-ignore
    const { data: costcenters, isPending: isCostCentersPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Cost Center', fields: ['`tabCost Center`.`name`'] },
        'POST'
    );

    // @ts-ignore
    const { data: accounts, isPending: AccountsPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Account', fields: ['`tabAccount`.`name`'] },
        'POST'
    );

    // @ts-ignore
    const { data: suppliers, isPending: SuppliersPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Supplier', fields: ['`tabSupplier`.`name`'] },
        'POST'
    );

    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Brand&name=${id}`);

    const [loadingButton, setLoadingButton] = useState(false);

    const [formState, setFormState] = useState({
        brand: '',
        description: '',
        code_brand: ''
    });

    const handleChange = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });
    };

    const [brandDefaults, setBrandsDefaults] = useState([
        {
            docstatus: 0,
            doctype: 'Item Default',
            __islocal: 1,
            __unsaved: 1,
            company: '',
            parentfield: 'brand_defaults',
            parenttype: 'Brand',
            default_warehouse: '',
            default_price_list: '',
            default_discount_account: '',
            buying_cost_center: '',
            expense_account: '',
            default_supplier: '',
            default_provisional_account: '',
            selling_cost_center: '',
            income_account: ''
        }
    ]);

    const handleBrandsDefaultsChange = (index, field, value) => {
        const updatedDefaults = [...brandDefaults];
        updatedDefaults[index][field] = value;
        setBrandsDefaults(updatedDefaults);
    };

    const addNewDefaults = () => {
        setBrandsDefaults([
            ...brandDefaults,
            {
                docstatus: 0,
                doctype: 'Item Default',
                __islocal: 1,
                __unsaved: 1,
                company: '',
                parentfield: 'brand_defaults',
                parenttype: 'Brand',
                default_warehouse: '',
                default_price_list: '',
                default_discount_account: '',
                buying_cost_center: '',
                expense_account: '',
                default_supplier: '',
                default_provisional_account: '',
                selling_cost_center: '',
                income_account: ''
            }
        ]);
    };

    const editbrand = {
        ...(id && {
            // @ts-ignore
            creation: doc.creation,
            // @ts-ignore
            modified: doc.modified,
            // @ts-ignore
            owner: doc.owner,
            // @ts-ignore
            modified_by: doc.owner,
            // @ts-ignore
            name: doc.name,
            code_brand: formState.code_brand,
            brand: formState.brand,
            description: formState.description,
            brand_defaults: brandDefaults
        }),
        doctype: 'Brand',
        brand: formState.brand,
        code_brand: formState.code_brand,
        description: formState.description,
        brand_defaults: brandDefaults
    };

    const handleEditBrand = async () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: editbrand, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    if (typeof data.message === 'string') {
                        if (data.message.includes('already exists')) {
                            dispatch(SetNotification({ code: 'warning', message: t('common:marqueExists') }));

                        }
                        // dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                    }
                } else {
                    dispatch(
                        SetNotification({
                            code: 'success',
                            message: `${t('common:La_Marque_a_ete')} ${id ? t('common:modifié') : t('common:crée')} ${t(
                                'common:avec_success'
                            )}`
                        })
                    );
                    navigate('/pim/brand');
                }
            });
    };

    useEffect(() => {
        if (id && !Array.isArray(doc) && doc) {
            setFormState({
                ...formState,
                // @ts-ignore
                code_brand: doc.code_brand,
                // @ts-ignore
                brand: doc.brand,
                // @ts-ignore
                description: doc.description
            });
            // @ts-ignore
            if (doc?.brand_defaults) {
                // @ts-ignore
                setBrandsDefaults(doc.brand_defaults);
            }
        }
    }, [id, doc]);

    const handleResetRow = (index) => {
        const updatedDefaults = [...brandDefaults];

        updatedDefaults.splice(index, 1);

        setBrandsDefaults(updatedDefaults);
    };

    const [editingIndex, setEditingIndex] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [editedDefaults, setEditedDefaults] = useState(null);

    const handleEditDefaults = (index) => {
        setEditingIndex(index);
        setEditedDefaults(brandDefaults[index]);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setEditingIndex(null);
        setModalOpen(false);
        setEditedDefaults(null);
    };

    const handleSaveDefaults = () => {
        const updatedDefaults = [...brandDefaults];
        updatedDefaults[editingIndex] = editedDefaults;
        setBrandsDefaults(updatedDefaults);
        handleCloseModal();
    };

    if (id && isPending) return <Loader />;

    return (
        <Box>
            <
                // @ts-ignore
                MainCard
                title={
                    <Box display={'flex'} flexDirection={'column'}>
                        <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
                        <Box display={'flex'} flexWrap="wrap">
                            {id ? `${t('products:brand_name')} : ${id}` : t('products:new_brand')}
                            {id && <EditIcon sx={{ fontSize: 17, ml: 1, cursor: 'pointer' }} onClick={() => setOpen(true)} />}
                        </Box>
                    </Box>
                }
            >
                <Box>
                    <Box>
                        <InputTextField
                            sx={{ mt: 0 }}
                            error={formState.code_brand === ''}
                            handleChange={handleChange}
                            value={formState.code_brand}
                            name="code_brand"
                            required
                            label={t('common:code_brand')}
                            id="outlined-size-small"
                            size="small"
                        />
                        {!id && (
                            <InputTextField
                                sx={{ mt: 0 }}
                                error={formState.brand === ''}
                                handleChange={handleChange}
                                value={formState?.brand}
                                name="brand"
                                required
                                label={t('products:brand_name')}
                                id="outlined-size-small"
                                size="small"
                            />
                        )}
                        <InputTextField
                            sx={{ mt: 0 }}
                            //error={formState.description === ''}
                            handleChange={handleChange}
                            value={formState.description}
                            name="description"
                            label={t('common:description')}
                            multiline
                            rows={6}
                            id="outlined-size-small"
                            size="small"
                        />
                    </Box>
                </Box>
                <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'} sx={{ mt: 2, mb: 1 }}>
                    <h3>{t('products:brand_defaults')}</h3>
                    <Button
                        // @ts-ignore
                        color="blue" onClick={addNewDefaults}>
                        <AddIcon sx={{ marginLeft: -1 }} /> {t('products:add_new_brand_defaults')}
                    </Button>
                    {brandDefaults.map((branddefaults, index) => (
                        <Grid container alignItems={'center'} spacing={1} key={index}>
                            <Grid item xs={3.3}>
                                <FormControl fullWidth sx={{ display: 'flex', alignItems: 'center' }}>
                                    <InputLabel id={`company-label-${index}`}>{t('crm:company')}</InputLabel>
                                    <Select
                                        labelId={`company-label-${index}`}
                                        id={`company-select-${index}`}
                                        label={t('crm:company')}
                                        value={branddefaults.company}
                                        onChange={(e) => handleBrandsDefaultsChange(index, 'company', e.target.value)}
                                        sx={{ mt: 2, mb: 1 }}
                                    >
                                        {companies &&
                                            companies.map((company) => (
                                                <MenuItem key={company.name} value={company.name} style={{ fontSize: 12 }}>
                                                    {company.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3.3}>
                                <FormControl fullWidth sx={{ display: 'flex', alignItems: 'center' }}>
                                    <InputLabel id={`warehouse-label-${index}`}>{t('products:default_warehouse')}</InputLabel>
                                    <Select
                                        labelId={`warehouse-label-${index}`}
                                        id={`warehouse-select-${index}`}
                                        label={t('products:default_warehouse')}
                                        value={branddefaults.default_warehouse}
                                        onChange={(e) => handleBrandsDefaultsChange(index, 'default_warehouse', e.target.value)}
                                        sx={{ mt: 2, mb: 1 }}
                                    >
                                        {warehouses &&
                                            warehouses.map((warehouse) => (
                                                <MenuItem key={warehouse.name} value={warehouse.name} style={{ fontSize: 12 }}>
                                                    {warehouse.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3.3}>
                                <FormControl fullWidth sx={{ display: 'flex', alignItems: 'center' }}>
                                    <InputLabel id={`default_price_list-${index}`}>{t('products:default_price_list')}</InputLabel>
                                    <Select
                                        labelId={`default_price_list-label-${index}`}
                                        id={`default_price_list-select-${index}`}
                                        label={t('products:default_price_list')}
                                        value={branddefaults.default_price_list}
                                        onChange={(e) => handleBrandsDefaultsChange(index, 'default_price_list', e.target.value)}
                                        sx={{ mt: 2, mb: 1 }}
                                    >
                                        {pricelists &&
                                            pricelists.map((pricelist) => (
                                                <MenuItem key={pricelist.name} value={pricelist.name} style={{ fontSize: 12 }}>
                                                    {pricelist.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={() => handleResetRow(index)}>
                                    <RestartAltIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={() => handleEditDefaults(index)}>
                                    <EditNoteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                    <Modal
                        open={modalOpen}
                        onClose={handleCloseModal}
                        closeAfterTransition
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <div
                            style={{
                                width: '70%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                padding: '0 40px',
                                borderRadius: '16px',
                                boxSizing: 'border-box',
                                backgroundColor: 'white',
                                height: '80%'
                            }}
                        >
                            <PerfectScrollbar
                                style={{
                                    overflow: 'scroll',
                                    height: 550,
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <div style={{ width: '100%' }}>
                                    <h3>{t('products:Edit_Brand_Defaults')}</h3>
                                    <hr></hr>
                                    <br></br>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('crm:company')}</InputLabel>
                                                <Select
                                                    value={editedDefaults ? editedDefaults.company : ''}
                                                    onChange={(e) => setEditedDefaults({ ...editedDefaults, company: e.target.value })}
                                                    sx={{ mt: 2, mb: 1 }}
                                                >
                                                    {companies &&
                                                        companies.map((company) => (
                                                            <MenuItem key={company.name} value={company.name} style={{ fontSize: 12 }}>
                                                                {company.name}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('products:default_warehouse')}</InputLabel>
                                                <Select
                                                    value={editedDefaults ? editedDefaults.default_warehouse : ''}
                                                    onChange={(e) =>
                                                        setEditedDefaults({ ...editedDefaults, default_warehouse: e.target.value })
                                                    }
                                                    sx={{ mt: 2, mb: 1 }}
                                                >
                                                    {warehouses &&
                                                        warehouses.map((warehouse) => (
                                                            <MenuItem key={warehouse.name} value={warehouse.name} style={{ fontSize: 12 }}>
                                                                {warehouse.name}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('products:Default_Buying_Cost_Center')}</InputLabel>
                                                <Select
                                                    value={editedDefaults ? editedDefaults.buying_cost_center : ''}
                                                    onChange={(e) =>
                                                        setEditedDefaults({ ...editedDefaults, buying_cost_center: e.target.value })
                                                    }
                                                    sx={{ mt: 2, mb: 1 }}
                                                >
                                                    {costcenters &&
                                                        costcenters.map((costcenter) => (
                                                            <MenuItem
                                                                key={costcenter.name}
                                                                value={costcenter.name}
                                                                style={{ fontSize: 12 }}
                                                            >
                                                                {costcenter.name}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('products:Default_Supplier')}</InputLabel>
                                                <Select
                                                    value={editedDefaults ? editedDefaults.default_supplier : ''}
                                                    onChange={(e) =>
                                                        setEditedDefaults({ ...editedDefaults, default_supplier: e.target.value })
                                                    }
                                                    sx={{ mt: 2, mb: 1 }}
                                                >
                                                    {suppliers &&
                                                        suppliers.map((supplier) => (
                                                            <MenuItem key={supplier.name} value={supplier.name} style={{ fontSize: 12 }}>
                                                                {supplier.name}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('products:Default_Selling_Cost_Center')}</InputLabel>
                                                <Select
                                                    value={editedDefaults ? editedDefaults.selling_cost_center : ''}
                                                    onChange={(e) =>
                                                        setEditedDefaults({ ...editedDefaults, selling_cost_center: e.target.value })
                                                    }
                                                    sx={{ mt: 2, mb: 1 }}
                                                >
                                                    {costcenters &&
                                                        costcenters.map((costcenter) => (
                                                            <MenuItem
                                                                key={costcenter.name}
                                                                value={costcenter.name}
                                                                style={{ fontSize: 12 }}
                                                            >
                                                                {costcenter.name}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('products:default_price_list')}</InputLabel>
                                                <Select
                                                    value={editedDefaults ? editedDefaults.default_price_list : ''}
                                                    onChange={(e) =>
                                                        setEditedDefaults({ ...editedDefaults, default_price_list: e.target.value })
                                                    }
                                                    sx={{ mt: 2, mb: 1 }}
                                                >
                                                    {pricelists &&
                                                        pricelists.map((pricelist) => (
                                                            <MenuItem key={pricelist.name} value={pricelist.name} style={{ fontSize: 12 }}>
                                                                {pricelist.name}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('products:default_discount_account')}</InputLabel>
                                                <Select
                                                    value={editedDefaults ? editedDefaults.default_discount_account : ''}
                                                    onChange={(e) =>
                                                        setEditedDefaults({ ...editedDefaults, default_discount_account: e.target.value })
                                                    }
                                                    sx={{ mt: 2, mb: 1 }}
                                                >
                                                    {accounts &&
                                                        accounts.map((account) => (
                                                            <MenuItem key={account.name} value={account.name} style={{ fontSize: 12 }}>
                                                                {account.name}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('products:expense_account')}</InputLabel>
                                                <Select
                                                    value={editedDefaults ? editedDefaults.expense_account : ''}
                                                    onChange={(e) =>
                                                        setEditedDefaults({ ...editedDefaults, expense_account: e.target.value })
                                                    }
                                                    sx={{ mt: 2, mb: 1 }}
                                                >
                                                    {accounts &&
                                                        accounts.map((account) => (
                                                            <MenuItem key={account.name} value={account.name} style={{ fontSize: 12 }}>
                                                                {account.name}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('products:Default_Provisional_Account')}</InputLabel>
                                                <Select
                                                    value={editedDefaults ? editedDefaults.default_provisional_account : ''}
                                                    onChange={(e) =>
                                                        setEditedDefaults({
                                                            ...editedDefaults,
                                                            default_provisional_account: e.target.value
                                                        })
                                                    }
                                                    sx={{ mt: 2, mb: 1 }}
                                                >
                                                    {accounts &&
                                                        accounts.map((account) => (
                                                            <MenuItem key={account.name} value={account.name} style={{ fontSize: 12 }}>
                                                                {account.name}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <InputLabel>{t('products:income_account')}</InputLabel>
                                                <Select
                                                    value={editedDefaults ? editedDefaults.income_account : ''}
                                                    onChange={(e) =>
                                                        setEditedDefaults({ ...editedDefaults, income_account: e.target.value })
                                                    }
                                                    sx={{ mt: 2, mb: 1 }}
                                                >
                                                    {accounts &&
                                                        accounts.map((account) => (
                                                            <MenuItem key={account.name} value={account.name} style={{ fontSize: 12 }}>
                                                                {account.name}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack direction="row" spacing={2} justifyContent="flex-end">
                                                <LoadingButton
                                                    // @ts-ignore
                                                    color="blue"
                                                    size="large"
                                                    variant="contained"
                                                    loading={loadingButton}
                                                    onClick={handleSaveDefaults}
                                                >
                                                    {t('common:save')}
                                                </LoadingButton>
                                                <Button
                                                    // @ts-ignore
                                                    color="blue" size="large" variant="outlined" onClick={handleCloseModal}>
                                                    {t('common:cancel')}
                                                </Button>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </div>
                            </PerfectScrollbar>
                        </div>
                    </Modal>
                </Grid>
                <Grid container xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Grid item xs={12}>
                        <Box display={'flex'} justifyContent={'center'} mt={4}>
                            <Button sx={{ mr: 1 }} onClick={() => navigate(-1)}>
                                {t('common:cancel')}
                            </Button>
                            <LoadingButton
                                disabled={formState.brand == ''}
                                size="large"
                                variant="contained"
                                // @ts-ignore
                                color="blue"
                                loading={loadingButton}
                                onClick={handleEditBrand}
                            >
                                {t('common:save')}
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </MainCard>
            <Modal onClose={handleClose} open={open}>
                <RenameForm
                    state={state_rename}
                    setState={setState_rename}
                    list={`/pim/edit-brand/${state_rename?.new_name}`}
                    message_success={t('products:crrrenamed')}
                    title={t('products:renamecrr')}
                    handleClose={handleClose}
                />
            </Modal>
        </Box>
    );
};

export default BrandForm;
