import React, { useEffect, useState } from 'react';
import { Button, Grid, Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useStyles } from 'views/utilities/Style';
import MainCard from 'ui-component/cards/MainCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'ui-component/Loader';
import useGetAxios from 'hooks/useGetAxios';
import { SetNotification } from 'store/services/api';
import { LoadingButton } from '@mui/lab';
import InputTextField from 'ui-component/form/InputTextField';
import { header, formatErpResponse } from 'utils/utils';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useFetch from '../../hooks/useFetch';
import FormDateTime from '../../ui-component/dateTime/FormDateTime';
import SelectForm from '../../ui-component/form/SelectForm';
import { Autocomplete, TextField } from '@mui/material';

const ItemPriceForm = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t } = useTranslation();

    const [loadingButton, setLoadingButton] = useState(false);
    const { id } = useParams();
    const globalDefaults = useSelector((state) => state.login.globalDefaults);

    const itemReqData = {
        doctype: 'Item',
        fields: ['`tabItem`.`name`', '`tabItem`.`item_tax_category`'],
        start: 0,
        page_length: 500
    };
    const itemUOMReqData = {
        doctype: 'UOM',
        fields: ['`tabUOM`.`name`'],
        start: 0,
        page_length: 100
    };
    const { data: priceLists, isPriceListsPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Price List',
            fields: ['`tabPrice List`.`name`', '`tabPrice List`.`currency`', '`tabPrice List`.`buying`', '`tabPrice List`.`selling`'],
            filters: [['Price List', 'enabled', '=', 1]],
            start: 0,
            page_length: 50
        },
        'POST'
    );
    const { data: companies, isCompaniesPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Company',
            fields: ['`tabCompany`.`name`'],
            start: 0,
            page_length: 500
        },
        'POST'
    );
    const { data: customer, isCustomerPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Customer', fields: ['`tabCustomer`.`name`'], start: 0, page_length: 50 },
        'POST'
    );

    const warehouseReqData = {
        doctype: 'Warehouse',
        fields: ['`tabWarehouse`.`name`'],
        filters: [['Warehouse', 'is_storage', '=', 0]],
        start: 0,
        page_length: 500
    };

    const { data: warehouses, isWarehousesPending } = useFetch(`/api/get-list`, warehouseReqData, 'POST');

    const { data: uoms, isUomsPending } = useFetch(`/api/get-list`, itemUOMReqData, 'POST');

    const { data: item, isPendingItem, refetch } = useFetch(`/api/get-list`, itemReqData, 'POST');

    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Item%20Price&name=${id}`);
    const [state, setState] = useState({
        item_code: '',
        price_list: '',
        price_list_rate: '',
        uom: '',
        packing_unit: '',
        //customer: '',
        valid_from: '',
        valid_upto: '',
        lead_time_days: '',
        buying: '',
        selling: '',
        company: '',
        warehouse: '',
        item_tax_category: '',
        item_price_ttc: '',
        lastUpdated: ''
    });
    const [error, setError] = React.useState({
        item_code: true,
        price_list_rate: true,
        price_list: true
    });

    const calculateTTC = (priceHT, itemTaxCategory) => {
        if (!priceHT || !itemTaxCategory || !taxetemplatedetails) return '';
        const matchingTaxDetail = taxetemplatedetails.find((detail) => detail.parent === itemTaxCategory);
        if (!matchingTaxDetail) return priceHT;
        const taxRate = matchingTaxDetail.tax_rate;
        if (!taxRate) return priceHT;
        const ttcPrice = parseFloat(priceHT) * (1 + taxRate / 100);
        return ttcPrice.toFixed(2);
    };

    const calculateHT = (priceTTC, itemTaxCategory) => {
        if (!priceTTC || !itemTaxCategory || !taxetemplatedetails) return '';
        const matchingTaxDetail = taxetemplatedetails.find((detail) => detail.parent === itemTaxCategory);
        if (!matchingTaxDetail) return priceTTC;
        const taxRate = matchingTaxDetail.tax_rate;
        if (!taxRate) return priceTTC;
        const htPrice = parseFloat(priceTTC) / (1 + taxRate / 100);
        return htPrice.toFixed(2);
    };

    const getTaxRate = (itemTaxCategory) => {
        if (!itemTaxCategory || !taxetemplatedetails) return null;

        const matchingTaxDetail = taxetemplatedetails.find((detail) => detail.parent === itemTaxCategory);
        return matchingTaxDetail ? matchingTaxDetail.tax_rate : null;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState((prevState) => {
            const newState = { ...prevState, [name]: value, lastUpdated: name };

            if (name === 'price_list_rate' && prevState.item_tax_category) {
                newState.item_price_ttc = calculateTTC(value, prevState.item_tax_category);
            } else if (name === 'item_price_ttc' && prevState.item_tax_category) {
                newState.price_list_rate = calculateHT(value, prevState.item_tax_category);
            }

            return newState;
        });
        setError((prevError) => ({ ...prevError, [name]: false }));
    };

    const handleChangeSelect = (event) => {
        const selectedPriceList = priceLists.find((priceList) => priceList.name === event.target.value);

        setError({ ...error, [event.target.name]: false });
        setState({
            ...state,
            [event.target.name]: event.target.value,
            buying: selectedPriceList?.buying || false,
            selling: selectedPriceList?.selling || false
        });
    };

    const itempriceData = {
        ...(id && {
            creation: doc.creation,
            modified: doc.modified,
            owner: doc.owner,
            modified_by: doc.owner,
            name: doc.name
        }),
        doctype: 'Item Price',
        item_code: state.item_code,
        price_list: state.price_list,
        price_list_rate: state.price_list_rate,
        uom: state.uom,
        packing_unit: state.packing_unit,
        //customer: state.customer,
        valid_from: state.valid_from,
        valid_upto: state.valid_upto,
        lead_time_days: state.lead_time_days,
        buying: state.buying,
        selling: state.selling,
        company: state.company,
        warehouse: state.warehouse,
        item_tax_category: state.item_tax_category,
        item_price_ttc: state.item_price_ttc
    };

    const { data: taxetemplatedetails, isTaxeTemplateDetailsPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Item Tax Template Detail',
            fields: ['*'],
            start: 0,
            page_length: 50
        },
        'POST'
    );

    const handleAddItemPrice = async () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: itempriceData, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(
                        SetNotification({
                            message: id ? t('products:tarifmoddif_success') : t('products:tarifadd_success'),
                            code: 'success'
                        })
                    );
                    navigate('/pim/tarif-article');
                }
            });
    };
    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            <Box display={'flex'} flexWrap="wrap">
                {id ? `${t('products:itemP')} : ${doc.item_code}` : t('products:newitemP')}
            </Box>
        </Box>
    );

    useEffect(() => {
        if (taxetemplatedetails && state.item_tax_category) {
            if (state.lastUpdated === 'price_list_rate') {
                setState((prevState) => ({
                    ...prevState,
                    item_price_ttc: calculateTTC(prevState.price_list_rate, prevState.item_tax_category)
                }));
            } else if (state.lastUpdated === 'item_price_ttc') {
                setState((prevState) => ({
                    ...prevState,
                    price_list_rate: calculateHT(prevState.item_price_ttc, prevState.item_tax_category)
                }));
            }
        }
    }, [taxetemplatedetails, state.item_tax_category, state.lastUpdated]);

    useEffect(() => {
        if (id && !Array.isArray(doc) && doc) {
            setState({
                ...state,
                item_code: doc.item_code,
                price_list: doc.price_list,
                price_list_rate: doc.price_list_rate,
                uom: doc.uom,
                packing_unit: doc.packing_unit,
                //customer: doc.customer,
                valid_from: doc.valid_from,
                valid_upto: doc.valid_upto,
                lead_time_days: doc.lead_time_days,
                buying: doc.buying,
                selling: doc.selling,
                company: doc.company,
                warehouse: doc.warehouse,
                item_tax_category: doc.item_tax_category,
                item_price_ttc: doc.item_price_ttc
            });
        }
    }, [id, doc]);

    if ((id && isPending && isPriceListsPending && isCustomerPending && isUomsPending && isPendingItem) || isCompaniesPending)
        return <Loader />;
    return (
        <div>
            <Box>
                <MainCard title={title}>
                    <Grid container rowSpacing={2}>
                        <Autocomplete
                            sx={{ width: '43.5%', ml: 33 }}
                            options={item || []}
                            getOptionLabel={(option) => option.name}
                            value={item?.find((i) => i.name === state.item_code) || null}
                            onChange={(event, newValue) => {
                                setState((prevState) => {
                                    const newState = {
                                        ...prevState,
                                        item_code: newValue ? newValue.name : '',
                                        item_tax_category: newValue ? newValue.item_tax_category || '' : ''
                                    };
                                    if (newState.price_list_rate) {
                                        newState.item_price_ttc = calculateTTC(newState.price_list_rate, newState.item_tax_category);
                                    }
                                    return newState;
                                });
                                setError((prevError) => ({ ...prevError, item_code: false }));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('products:item_code')}
                                    required
                                    error={error.item_code}
                                    helperText={error.item_code ? t('products:isfieldrequired') : ''}
                                />
                            )}
                        />
                        <SelectForm
                            sx={{ width: '50%' }}
                            required={false}
                            label={t('products:uom')}
                            value={state?.uom}
                            error={error.uom}
                            data={uoms}
                            propToRender={'name'}
                            name="uom"
                            handleChange={handleChangeSelect}
                        />
                        {/* <InputTextField
                            label={t('products:pu')}
                            value={state?.packing_unit}
                            error={error.packing_unit}
                            name="packing_unit"
                            handleChange={handleChange}
                            helper={t('products:qtms')}
                        />*/}
                        <SelectForm
                            sx={{ width: '50%' }}
                            required={false}
                            label={t('columns:company')}
                            value={state?.company}
                            data={companies}
                            propToRender={'name'}
                            name="company"
                            handleChange={handleChangeSelect}
                        />
                        <SelectForm
                            sx={{ width: '50%' }}
                            required={false}
                            label={t('columns:warehouse')}
                            value={state?.warehouse}
                            data={warehouses}
                            propToRender={'name'}
                            name="warehouse"
                            handleChange={handleChangeSelect}
                        />
                        <SelectForm
                            sx={{ width: '50%' }}
                            required
                            label={t('products:typt')}
                            value={state?.price_list}
                            error={error.price_list}
                            data={priceLists}
                            propToRender={'name'}
                            name="price_list"
                            handleChange={handleChangeSelect}
                        />
                        <FormControlLabel
                            sx={{ ml: 2, mt: 2 }}
                            control={<Checkbox name="buying" checked={state?.buying} disabled />}
                            label={t('common:buying')}
                        />
                        <FormControlLabel
                            sx={{ mt: 2 }}
                            control={<Checkbox name="selling" checked={state?.selling} disabled />}
                            label={t('common:selling')}
                        />
                        {/*<SelectForm
                            sx={{ width: '50%' }}
                            required={false}
                            label={t('crm:customers')}
                            value={state?.customer}
                            error={error.customer}
                            data={customer}
                            propToRender={'name'}
                            name="customer"
                            handleChange={handleChangeSelect}
                        />*/}
                        <InputTextField
                            label={t('products:price_ht')}
                            required
                            value={state?.price_list_rate}
                            error={error.price_list_rate}
                            name="price_list_rate"
                            handleChange={handleChange}
                        />
                        <InputTextField
                            label={t('products:Taxe')}
                            value={state.item_tax_category}
                            name="item_tax_category"
                            handleChange={handleChange}
                            disabled
                            sx={{ opacity: 0.7, pointerEvents: 'none' }}
                        />
                        <Typography variant="body2" color="textSecondary" sx={{ mt: 4, ml: 3 }}>
                            {getTaxRate(state.item_tax_category) !== null
                                ? `(${t('core:taxrate')}: ${getTaxRate(state.item_tax_category)}%)`
                                : `(${t('products:NoTaxRateFound')})`}
                        </Typography>
                        <InputTextField
                            label={t('products:price_ttc')}
                            value={state?.item_price_ttc}
                            name="item_price_ttc"
                            handleChange={handleChange}
                        />

                        <FormDateTime
                            type="date"
                            label={t('crm:fromdate')}
                            name="valid_from"
                            value={state.valid_from}
                            handleChange={handleChange}
                            isValidDate
                        />
                        <FormDateTime
                            type="date"
                            label={t('products:to_range')}
                            name="valid_upto"
                            value={state.valid_upto}
                            handleChange={handleChange}
                            isValidDate
                        />
                        {/* <InputTextField
                            label={t('products:den')}
                            value={state?.lead_time_days}
                            error={error.lead_time_days}
                            name="lead_time_days"
                            handleChange={handleChange}
                        />*/}
                    </Grid>
                    <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                        <Button sx={{ mr: 1 }} onClick={() => navigate(-1)}>
                            {t('common:cancel')}
                        </Button>
                        <LoadingButton
                            onClick={handleAddItemPrice}
                            size="large"
                            variant="contained"
                            color="blue"
                            disabled={(state.item_code && state.price_list && state.price_list_rate) === ''}
                            loading={loadingButton}
                        >
                            {t('common:save')}
                        </LoadingButton>
                    </Box>
                </MainCard>
            </Box>
        </div>
    );
};

export default ItemPriceForm;
